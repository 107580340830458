import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { VStack } from "@chakra-ui/react";

import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import { AppContext } from "../../../AppContext/AppContext";

import useFetchStore from "./Hooks/useFetchStore";
import MyStoreHeader from "./components/MyStoreHeader";
import MyStoreTable from "./components/MyStoreTable/MyStoreTable";
import MyStoreModal from "./components/MyStoreModal/MyStoreModal";

import "./Mystore.css";

export const Chakra_Styles = {
  actionMenuItem: {
    fontSize: "small",
  },
};

function MyStore() {
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [archive, setArchive] = useState(null);
  const { seller } = useContext(AppContext);
  const [filter, setFilter] = useState("all");

  const navigate = useNavigate();

  const stores = useFetchStore({ seller, filter, toggleFetch });

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleFilter(selectedOption) {
    setFilter(selectedOption.value);
  }

  return (
    <>
      <VStack w="full" align="stretch">
        <MyStoreHeader
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
          filter={filter}
          handleFilter={handleFilter}
          stores={stores}
        />
        <MyStoreTable
          stores={stores}
          setShowModal={setShowModal}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setTargetID={setTargetID}
          setArchive={setArchive}
          navigate={navigate}
        />
      </VStack>

      <MyStoreModal
        showModal={showModal}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        handleClose={handleClose}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
        targetID={targetID}
        triggerFetch={triggerFetch}
        seller={seller}
        archive={archive}
      />
    </>
  );
}

export default MyStore;
