import { RiGiftLine } from "react-icons/ri";
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList";
import { useParams } from "react-router-dom";

function ProductFeatures() {
  const { storeId } = useParams();

  return (
    <NavCardList numOfCards="five">
      <NavCard
        cardName={`Size`}
        navCardLink={`/mystores/productsSize/${storeId}`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`Color`}
        navCardLink={`/mystores/productsColor/${storeId}`}
      >
        <RiGiftLine />
      </NavCard>
    </NavCardList>
  );
}

export default ProductFeatures;
