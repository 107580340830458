import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { PRODUCT_SIZE_API, SIZE_API } from "../../../../../../../Utilities/APIs/APIs";


function UpdateProductSize({ setShowUpdateForm, setShowModal, triggerFetch,targetID }) {
 
    const [size, setSize] = useState("");
    const [sizes, setSizes] = useState(null);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
      async function fetchAndSetProductSize() {
        const { data } = await axiosInstance.get(PRODUCT_SIZE_API +"getSingleProductSize/" + targetID);
        setSize(data.size._id);
      }
      fetchAndSetProductSize()
    }, [axiosInstance, targetID])

  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(SIZE_API + "getAllSize");
      setSizes(data);
    }
    fetchAndSetSizes();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    
    itemData.append("size", size);

    const response = await axiosInstance.patch(PRODUCT_SIZE_API + "updateProductSize/"+ targetID, itemData);
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
      setShowModal(false)
    }
  }

  console.log(sizes)
  console.log(size)

  return (
    <Form onSubmit={handleSubmit} hasImage>
     
     <SelectInput label={"Size"} value={size} setState={setSize}>
            <SelectOption optionValue="" optionText="Select Size" />
            {sizes?.map((size) => (
              <SelectOption
                key={size._id}
                optionValue={size._id}
                optionText={size?.name}
              />
            ))}
          </SelectInput>

    
      <FormSubmitButton text="Update Size" />
    </Form>
  );
}

export default UpdateProductSize;



