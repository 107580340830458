import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { COLOUR_API } from "../../../../../Utilities/APIs/APIs";


function ViewColor  ({targetID})  {
    const [color,setColor] = useState(null)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
      async function fetchAndsetColor() {
        const { data } = await axiosInstance.get(COLOUR_API+ targetID);
        setColor(data);
      }
      fetchAndsetColor();
    }, [targetID, axiosInstance,]);
    return (
        <div className="crud_view_content">
     
        <>
          <h1>Size Name</h1>
          <p>{color?.name}</p>

          <h1>Precedence</h1>
          <p>{color?.precedence}</p>
     
        </>

        </div>
    );
};

export default ViewColor;