import  {useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { COLOUR_API} from "../../../../../Utilities/APIs/APIs";

function UpdateColor({ setShowUpdateForm, setShowModal, triggerFetch,targetID }) {
    
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
 const [precedence,setPrecedence] = useState(null)

 useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get(COLOUR_API + targetID);
      setName(data.name);
      setPrecedence(data.precedence)
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance,]);


  async function handleSubmit(e) {
    e.preventDefault();

  const itemData = {name,precedence}
    

  const response = await axiosInstance.patch(COLOUR_API + targetID, itemData);
  console.log(response.data);

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  } 
  return (
    <Form onSubmit={handleSubmit} hasImage>
     
      <ShortTextInput
        label={`Colour Name`}
        value={name}
        placeholder={`Enter Colour Name`}
        setState={setName}
      />

      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />
     
      <FormSubmitButton text="Update Color" />
    </Form>
  );
}

export default UpdateColor;
