import { Text, VStack } from "@chakra-ui/react";
import Select from "react-select";

import React from "react";
import ShortTextInput from "../../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import PasswordInput from "../../../../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput";
import NumberInput from "../../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

export default function BottomSection({
  name,
  email,
  password,
  address,
  phoneNumber,
  type,
  options,
  handleType,
  nameError,
  emailError,
  passwordError,
  addressError,
  phoneNumberError,
  typeError,
  setName,
  setEmail,
  setPassword,
  setAddress,
  setPhoneNumber,
  setNameError,
  setEmailError,
  setPasswordError,
  setAddressError,
  setPhoneNumberError,
  setTypeError,
  apiState,
  menuPortalTarget,
  ReactSelectStyles,
}) {
  return (
    <div>
      <VStack align="stretch" spacing={"3"}>
        <ShortTextInput
          value={name}
          placeholder={`Name`}
          setState={setName}
          error={nameError}
          setError={setNameError}
        />
        {nameError && (
          <Text color="red" fontSize={12}>
            {nameError}
          </Text>
        )}
        <EmailInput
          value={email}
          placeholder={`Email`}
          setState={setEmail}
          setError={setEmailError}
        />
        {emailError && (
          <Text color="red" fontSize={12}>
            {emailError}
          </Text>
        )}
        <PasswordInput
          value={password}
          placeholder={`Password`}
          setState={setPassword}
          setError={setPasswordError}
        />
        {passwordError && (
          <Text color="red" fontSize={12}>
            {passwordError}
          </Text>
        )}
        <ShortTextInput
          value={address}
          placeholder={`Address`}
          setState={setAddress}
          setError={setAddressError}
        />
        {addressError && (
          <Text color="red" fontSize={12}>
            {addressError}
          </Text>
        )}
        <NumberInput
          value={phoneNumber}
          placeholder={`Phone Number`}
          setState={setPhoneNumber}
          setError={setPhoneNumberError}
        />
        {phoneNumberError && (
          <Text color="red" fontSize={12}>
            {phoneNumberError}
          </Text>
        )}
        <Select
          placeholder={"Select Type"}
          styles={ReactSelectStyles}
          maxMenuHeight={220}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          isClearable={false}
          value={options.find((option) => option.value === type)}
          onChange={handleType}
          options={options}
        />
        {typeError && (
          <Text color="red" fontSize={12}>
            {typeError}
          </Text>
        )}
      </VStack>

      <FormSubmitButton
        text="Sign up as a seller"
        isLoading={
          apiState.type === "register" && apiState.status === "pending"
        }
      />
    </div>
  );
}
