import { HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import RenderImageBox from "./RenderImageBox";
import RegTopTextInfo from "./components/RegTopTextInfo";

export default function RegTopSection({
  name,
  email,
  imagePreview,
  triggerImageModal,
  handleChange,
  dpError,
  fieldId,
}) {
  return (
    <div>
      <VStack align="flex-start">
        <HStack w="full" h="120px">
          <RenderImageBox
            imagePreview={imagePreview}
            triggerImageModal={triggerImageModal}
          />
          <RegTopTextInfo name={name} email={email} />
        </HStack>

        <input
          id={`fileInput${fieldId}`}
          type="file"
          className="file_upload_input"
          onChange={handleChange}
        />
      </VStack>
      {dpError && (
        <Text color="red" fontSize={12}>
          {dpError}
        </Text>
      )}
    </div>
  );
}
