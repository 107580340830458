import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_SIZE_API } from "../../../../../../Utilities/APIs/APIs";

export default function useFetchSize({ type, gender }) {
  const axiosInstance = useAxiosInstance();

  const [sizes, setSizes] = useState(null);

  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(
        PRODUCT_SIZE_API +
          `getAllSizeBySizeAndGender?type=${type}&&gender=${gender}`
      );
      const initialOptions = data.map((size) => ({
        label: size.name,
        value: size._id,
      }));
      setSizes(initialOptions);
    }
    fetchAndSetSizes();
  }, [axiosInstance, type, gender]);
  return sizes;
}
