import { background, HStack, Text } from "@chakra-ui/react";
import React from "react";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import Select from "react-select";
import { color } from "framer-motion";
import { hover } from "@testing-library/user-event/dist/hover";

export const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "240px",
    background: "#322a5d",
    color: state.isSelected ? "red" : "red",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "24px",
    color: "white",
    fontWeight: "400",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#322a5d",
    color: "white",
    fontSize: "24px",
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: "18px",
    width: "100%",
    color: state.isSelected ? "black" : "white",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    fontSize: "24px",
  }),
};
export default function ProductHeading({
  type,
  products,
  setShowCreateForm,
  setShowModal,
  store,
  setType,
}) {
  return (
    <div>
      <HStack justifyContent={"space-between"}>
        <HStack spacing="10" w="full" pb="5">
          <Text className="screen_heading" fontWeight="semibold">
            Products ({products?.length} in total)
          </Text>
          {type === "apparel" && store.permittedToSellApparel && (
            <CreateButton
              screenTopicSingular={"Product"}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
            />
          )}
          {type === "sneaker" && (
            <CreateButton
              screenTopicSingular={"Product"}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
            />
          )}
        </HStack>
        <HStack spacing="10" w="50%" pb="5" justify={"end"}>
          <Select
            options={[
              { value: "", label: "All" },
              { value: "sneaker", label: "Sneaker" },
              { value: "apparel", label: "Apparel" },
            ]}
            styles={ReactSelectStyles}
            placeholder="Filter by type"
            onChange={(e) => setType(e.value)}
          />
        </HStack>
      </HStack>
    </div>
  );
}
