// Register.jsx

import { Route, Routes } from "react-router-dom";

import RegistrationForm from "./RegistrationForm/RegistrationForm";
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import AddStoreForm from "./AddStoreForm/AddStoreForm";
import "./Register.module.css";
import { VStack } from "@chakra-ui/react";
import ScreenHolder from "../../../Partials/Layouts/ScreenHolder/ScreenHolder";

function Register() {
  return (
    <ScreenHolder>
      <VStack
        overflow="scroll"
        spacing="15"
        bg="white"
        p="10"
        borderRadius="md"
        boxShadow={"0px 0px 30px 4px rgba(0,0,0,0.05)"}
      >
        <Routes>
          <Route path="/" element={<RegistrationForm />} />
          <Route path="verify-mail-instruction" element={<VerifyEmail />} />
          <Route path="add-store/:token" element={<AddStoreForm />} />
          {/* <Route path='verify-store' element={<VerifyStore />} /> */}
        </Routes>
      </VStack>
    </ScreenHolder>
  );
}

export default Register;
