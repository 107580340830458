import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { EMPLOYEES_API } from '../../../Utilities/APIs';
import ScreenHolder from "../../../../Partials/Layouts/ScreenHolder/ScreenHolder";

import "../../Auth.css";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";

function RecoverPassThree() {
  const [newPassword, setnewPassword] = useState("");
  const [newPassword2, setnewPassword2] = useState("");

  const { token } = useParams();
  const navigate = useNavigate();
  const axiosAuthInstance = useAxiosAuthInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    if (newPassword !== newPassword2) {
      console.log("Passwords Do Not Match");
    }

    const formBody = {
      token,
      newPassword: newPassword,
    };

    const changePassword = await axiosAuthInstance.patch(
      "resetSellerPassword",
      formBody
    );

    if (changePassword) {
      console.log(changePassword);
      setnewPassword("");
      setnewPassword2("");
      navigate("/login");
    }
  }

  return (
    <ScreenHolder>
      <div className="form_wrapper">
        <h3>Recover Password</h3>
        <form action="/">
          <div className="input_group">
            <label className="input_field_label caption bold">
              new password
            </label>
            <input
              type="password"
              className="input_field body_text"
              placeholder="Enter password"
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
            />
          </div>
          <div className="input_group">
            <label className="input_field_label caption bold">
              confirm password
            </label>
            <input
              type="password"
              className="input_field body_text"
              placeholder="Enter password"
              value={newPassword2}
              onChange={(e) => setnewPassword2(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn_text submit"
            onClick={handleSubmit}
          >
            change password
          </button>
        </form>
        <Link to="/login" className="btn_text forgot_pass">
          Back to Login
        </Link>
      </div>
    </ScreenHolder>
  );
}

export default RecoverPassThree;
