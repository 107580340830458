import { HStack, Text } from "@chakra-ui/react";
import React from "react";

export default function PaymentDetailsHedling({ approvePaymentCount }) {
  return (
    <div>
      <HStack spacing="10" w="full" pb="5">
        <Text className="screen_heading" fontWeight="semibold">
          {approvePaymentCount > 0
            ? `You have total ${approvePaymentCount} new payment`
            : "All Payment Status"}

          {/* Product Size ({productSizes?.length} in total) */}
        </Text>
      </HStack>
    </div>
  );
}
