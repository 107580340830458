import { Button } from "@chakra-ui/react";
import "./SidebarItem.css";
import { useLocation, useNavigate } from "react-router-dom";

function SidebarItem({ icon, title, number }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isActive = () => {
    // Check if the current location pathname includes the route corresponding to the title
    return pathname.includes(`/${title.toLowerCase().replace(/\s/g, "")}`);
  };

  const _activeStyle = isActive() && {
    bg: "#322A5D",
    color: "white",
    _hover: {
      bg: "#322A5D",
    },
  };

  function goToPage() {
    navigate(title.toLowerCase().replace(/\s/g, ""));
  }

  return (
    <Button
      w="100%"
      colorScheme="ghost"
      leftIcon={icon}
      bg="transparent"
      color={"#322A5D"}
      _hover={{
        bg: "#322A5D30",
      }}
      fontSize="small"
      justifyContent="flex-start"
      paddingY="18px"
      onClick={goToPage}
      {..._activeStyle}
    >
      {title}
    </Button>
  );
}

export default SidebarItem;
