import React, { useEffect, useState } from "react";
import TotalOrderPayments from "../TotalOrderPayments";
import TotalPaidPayment from "../TotalPaidPayment";
import TotalPayablePayment from "../TotalPayablePayment";

export default function OrderCards({ totalOrderValue, totalPaidPayment }) {
  const [totalPayablePayment, setTotalPayablePayment] = useState("");

  useEffect(() => {
    if (totalOrderValue !== "" && totalPaidPayment !== "") {
      const payable = totalOrderValue - totalPaidPayment;
      setTotalPayablePayment(payable);
    }
  }, [totalOrderValue, totalPaidPayment]);
  return (
    <div className="dashboard">
      <TotalOrderPayments totalOrderValue={totalOrderValue} />
      <TotalPaidPayment totalPaidPayment={totalPaidPayment} />
      <TotalPayablePayment totalPayablePayment={totalPayablePayment} />
    </div>
  );
}
