import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";

import {
  PRODUCT_API,
  PRODUCT_COLOUR_API,
  SINGLE_PRODUCT_API,
} from "../../../../../../Utilities/APIs/APIs";

import { AppContext } from "../../../../../../AppContext/AppContext";
import ProductColorHeading from "./components/ProductColorHeading";
import ProductColorTable from "./components/ProductColorTable/ProductColorTable";
import ProductColorModal from "./components/ProductColorModal/ProductColorModal";

function Color() {
  const { productId } = useParams();

  const [productColor, setproductColor] = useState(null);
  const [productName, setProductName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { seller } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  // const navigate = useNavigate()
  useEffect(() => {
    async function fetchAndSetProductColor() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLOUR_API + "getColorByProductId/" + productId
      );
      console.log("Data got:", data);
      setproductColor(data);
    }
    fetchAndSetProductColor();
  }, [axiosInstance, toggleFetch, productId]);

  useEffect(() => {
    async function fetchAndSetProductName() {
      const { data } = await axiosInstance.get(
        PRODUCT_API + SINGLE_PRODUCT_API + productId
      );
      setProductName(data.name);
    }
    fetchAndSetProductName();
  }, [axiosInstance, productId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <ProductColorHeading
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
          productColor={productColor}
        />
        <ProductColorTable
          productName={productName}
          productColor={productColor}
          setShowModal={setShowModal}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setTargetID={setTargetID}
          triggerFetch={triggerFetch}
          seller={seller}
          productId={productId}
        />
      </div>

      <ProductColorModal
        showModal={showModal}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        handleClose={handleClose}
        productId={productId}
        targetID={targetID}
        triggerFetch={triggerFetch}
        seller={seller}
        productName={productName}
        setShowCreateForm={setShowCreateForm}
        setShowDeleteSection={setShowDeleteSection}
        setShowModal={setShowModal}
        setShowUpdateForm={setShowUpdateForm}
      />
    </>
  );
}

export default Color;
