import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_COLOUR_API } from "../../../../../../../Utilities/APIs/APIs";

function ViewProductColor({ productName, targetID }) {
  const [color, setColor] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndsetColor() {
      const { data } = await axiosInstance.get(PRODUCT_COLOUR_API + targetID);
      setColor(data.color.name);
    }
    fetchAndsetColor();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      <h1>Product</h1>
      <p>{productName}</p>

      <h1>Color</h1>
      <p>{color}</p>

      {/* <h1>Quantity</h1>
           <p>{size}</p> */}
    </div>
  );
}

export default ViewProductColor;
