import { RiGiftLine } from "react-icons/ri";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import { useParams } from "react-router-dom";

function ProductAsset() {
  const { storeId } = useParams();
  return (
    <NavCardList numOfCards="five">
      <NavCard
        cardName={`Products`}
        navCardLink={`/mystores/products/${storeId}`}
      >
        <RiGiftLine />
      </NavCard>
    </NavCardList>
  );
}

export default ProductAsset;
