import React from "react";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CreatePaymentCardInfo from "./components/CreatePaymentCardInfo";
import EditPaymentCardInfo from "./components/EditPaymentCardInfo";
import ViewPaymentCardInfo from "./components/ViewPaymentCardInfo";

export default function PaymentInfoModal({
  showCreateForm,
  showUpdateForm,
  showViewSection,
  showModal,
  targetID,
  handleClose,
  triggerFetch,
  seller,
  setShowCreateForm,
  setShowModal,
  setShowUpdateForm,
}) {
  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Card Info`) ||
            (showUpdateForm && `Update Card Info `) ||
            // (showDeleteSection && `Delete Card Info`) ||
            (showViewSection && `View Card Info`)
          }
        >
          {showCreateForm && (
            <CreatePaymentCardInfo
              seller={seller}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <EditPaymentCardInfo
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showViewSection && (
            <ViewPaymentCardInfo targetID={targetID} seller={seller} />
          )}
        </Modal>
      )}
    </div>
  );
}
