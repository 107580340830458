import { useEffect, useState } from "react";

function Stock({stock, setStock}) {
    const [inputValue, setInputValue] = useState(0);

  
    useEffect(() => {
        setInputValue(stock);
    }, [stock]);

    const handleChange = (e) => {
        const newStock = e.target.value;
        setInputValue(newStock);
        setStock(newStock);
    };
    return (
        <input
            type="number"
            placeholder="Enter Stock"
            value={inputValue} // Bind the input value to the state
            onChange={handleChange}

            // name="price"
            style={{
                width: "100%",
                minHeight: "4rem",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0.5rem",
            }}
        />
    )
}

export default Stock