import React from "react";
import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { Chakra_Styles } from "../../../../../MyStore/MyStore";
import { IoChevronDown } from "react-icons/io5";
import CRUDButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";

export default function PaymentDetailsTable({
  allPaymentsToSeller,
  setShowModal,
  setShowApprove,
  setTargetID,
}) {
  return (
    <div>
      <CRUDTable>
        <tbody>
          {allPaymentsToSeller?.map((payment) => {
            const date = new Date(payment.dateOfPayment).getDate();
            const month = new Date(payment.dateOfPayment).getMonth();
            const year = new Date(payment.dateOfPayment).getFullYear();
            return (
              <CRUDTableRow key={payment?._id}>
                <ShortTextCell text={`${date}/${month}/${year}`} />
                <ShortTextCell text={payment?.amount} />

                {payment.isApprove ? (
                  <td>
                    <Text {...Chakra_Styles.actionMenuItem}>Approve</Text>
                  </td>
                ) : (
                  <td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDown />}
                        size="md"
                        bg="#322A5D30"
                        // color='white'
                        _hover={{
                          bg: "#322A5D",
                          color: "white",
                        }}
                        _active={{
                          bg: "#322A5D",
                          color: "white",
                        }}
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        <CRUDButton
                          dataTip="Approve"
                          dataFor="Approve Item"
                          handleClick={() => {
                            setShowModal(true);
                            setShowApprove(true);
                            setTargetID(payment._id);
                          }}
                        >
                          <Text {...Chakra_Styles.actionMenuItem}>Approve</Text>
                        </CRUDButton>
                      </MenuList>
                    </Menu>
                  </td>
                )}
              </CRUDTableRow>
            );
          })}
        </tbody>
      </CRUDTable>
    </div>
  );
}
