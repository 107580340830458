import React, { useState, useEffect, useContext } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ALL_PRODUCT_BY_TYPE_API } from "../../../../Utilities/APIs/APIs";
import { AppContext } from "../../../../AppContext/AppContext";

const SearchProduct = ({ onProductSelect, type }) => {
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [options, setOptions] = useState([]);
  const axiosInstance = useAxiosInstance();
  const { seller } = useContext(AppContext);
  // Load initial options when the component mounts

  console.log(type);
  useEffect(() => {
    const loadInitialOptions = async () => {
      try {
        const { data } = await axiosInstance.get(
          `product/getAllProductByType?type=${type}&&page=${page}&&search=${inputValue}`
        );
        const initialOptions = data.products.map((product) => ({
          label: product.name,
          value: product._id,
        }));
        setOptions(initialOptions);
      } catch (err) {
        console.error(err);
      }
    };

    loadInitialOptions();
  }, [axiosInstance, type, seller, page, inputValue]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 2) {
      try {
        const { data } = await axiosInstance.get(
          `product/getAllProductByType?type=${type}&&page=${page}&&search=${inputValue}`
        );
        const newOptions = data.products.map((product) => ({
          label: product.name,
          value: product._id,
        }));
        setHasMore(data.data.length >= 10);
        setOptions(newOptions);
        callback(newOptions);
      } catch (err) {
        console.error(err);
        callback([]);
      }
    } else {
      callback(options);
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setPage(1);
    setHasMore(true);
    return newValue;
  };

  const loadMoreOptions = async (inputValue, callback) => {
    if (!hasMore) return;

    try {
      const { data } = await axiosInstance.get(
        `product/getAllProductByType?type=${type}&&page=${page}&&search=${inputValue}`
      );
      const newOptions = data.products.map((product) => ({
        label: product.name,
        value: product._id,
      }));
      setHasMore(data.products.length >= 10);
      setPage((prevPage) => prevPage + 1);
      setOptions((prevOptions) => [...prevOptions, ...newOptions]);
      callback(newOptions);
    } catch (err) {
      console.error(err);
      callback([]);
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onMenuScrollToBottom={() => loadMoreOptions(inputValue, (options) => {})}
      onChange={(selectedOption) => onProductSelect(selectedOption)}
      defaultOptions={options}
      placeholder="Please select a product..."
    />
  );
};

export default SearchProduct;
