import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_API } from "../../../../Utilities/APIs/APIs";
import AsyncSelect from "react-select/async";

export default function SelectProduct({ setRecommendedItems, storeId }) {
  const [options, setOptions] = useState([]);
  const axiosInstance = useAxiosInstance();
  // Load initial options when the component mounts

  useEffect(() => {
    const loadInitialOptions = async () => {
      try {
        const response = await axiosInstance.get(
          PRODUCT_API + `/getSellerStoreProducts/${storeId}`,
          {
            params: {
              page: 1,
              limit: 20,
              storeId: storeId,
            },
          }
        );
        const initialOptions = response.data.map((product) => ({
          label: product.product.name,
          value: product.product._id,
        }));
        setOptions(initialOptions);
      } catch (err) {
        console.error(err);
      }
    };

    loadInitialOptions();
  }, [axiosInstance, storeId]);

  return (
    <div>
      <AsyncSelect
        isMulti
        cacheOptions
        closeMenuOnSelect={false}
        defaultOptions={options}
        placeholder="Recommended some products"
        // options={options}
        onChange={(e) => setRecommendedItems(e.map((id) => id.value))}
      />
    </div>
  );
}
