import useAllPaymentInfo from "./Hooks/useAllPaymentInfo";

import OrderCards from "./components/OrderCards";

import "./Dashboard.css";
import PaymentDetails from "./components/PaymentDetials/PaymentDetails";

function Dashboard() {
  const {
    totalOrderValue,
    totalPaidPayment,
    approvePaymentCount,
    allPaymentsToSeller,
  } = useAllPaymentInfo();

  console.log("total order value is");
  return (
    <div className="dashboard_crud_holder">
      <OrderCards
        totalOrderValue={totalOrderValue}
        totalPaidPayment={totalPaidPayment}
      />
      <PaymentDetails
        approvePaymentCount={approvePaymentCount}
        allPaymentsToSeller={allPaymentsToSeller}
      />
    </div>
  );
}

export default Dashboard;
