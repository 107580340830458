import { Text } from "@chakra-ui/react";
import "./VerifyEmail.css";

function VerifyEmail() {
  return (
    <>
      <div className="verify_email_container">
        {/* <div className="text-logo-container">
        <h1 className="text-logo">
          CHE<span className="logo-coloring-text">B</span>
        </h1>
      </div> */}

        <Text
          fontSize={"20px"}
          fontWeight={"bold"}
          color="#5c5c5c"
          maxW="full"
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          marginBottom={30}
          textAlign={"center"}
        >
          Verify Email
        </Text>
        {/* <h3 className="text_heading">Verify Email</h3> */}

        {/* Instruction */}
        <ul className="instruction_container">
          <li>
            <Text fontSize={"small"}>1. Check Your Mail Inbox</Text>
          </li>
          <li>
            <Text fontSize={"small"}>
              2. Open email titled "Account Verification."
            </Text>
          </li>
          <li>
            <Text fontSize={"small"}>3. Click the Verification Link</Text>
          </li>
          <li>
            <Text fontSize={"small"}>
              {" "}
              4. Once you've clicked the link, you'll be redirected to our
              website, and your email will be verified. You're all set!
            </Text>
          </li>
        </ul>
      </div>
    </>
  );
}

export default VerifyEmail;
