import React from "react";
import { RiSearchLine } from "react-icons/ri";
import CreateButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function ProductSizeHeading({
  setShowCreateForm,
  setShowModal,
  sizes,
  search,
  setSearch,
}) {
  return (
    <div className="screen_header">
      <h1 className="screen_heading">Size ({sizes?.length} in total)</h1>
      <div>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="input_field"
          placeholder="Search"
          style={{ width: "400px" }}
        />
        <RiSearchLine
          style={{
            width: "30px",
            height: "30px",
            marginLeft: "-50px",
            color: "var(--white-2)",
          }}
        />
      </div>

      <CreateButton
        screenTopicSingular={"Size"}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
      />
    </div>
  );
}
