import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "react-slugify";

import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import EmailInput from "../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
// import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { STORE_API } from "../../../../../Utilities/APIs/APIs";
import "./AddStoreForm.css";
import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";


function AddStoreForm() {

  const navigate = useNavigate()

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");

  const { token } = useParams();
  const axiosInstance = useAxiosInstance()
  const axiosAuthInstance = useAxiosAuthInstance();

  const fieldId = 3
  useEffect(() => {
    if (token) {
      async function verifyCustomer() {
        const verification = await axiosAuthInstance.patch("verify/" + token);
        if (verification.data.isVerified === true) {
          localStorage.setItem("seller", JSON.stringify(verification.data));
        }
      }
      verifyCustomer();
    }
  }, [token, axiosAuthInstance]);




  const [imagePreview, setImagePreview] = useState({ preview: '' })
  const handleChange = e => {
    setImage(e.target.files[0])
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0])
    })
  }

  function triggerImageModal() {
    const input = document.getElementById(`fileInput${fieldId}`)
    input.click()
  }


  function renderImage() {
    return (
      <>
        {imagePreview.preview && (
          <Image
            objectFit='contain'
            w='80px'
            h='80px'
            src={imagePreview.preview}
          />
        )}
      </>
    )
  }
  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("email", email);
    itemData.append("mobile", mobile);
    itemData.append("address", address);
    itemData.append("image", image);
    const res = await axiosInstance.post(STORE_API + 'add-store/', itemData)
    if (res.data) {
      navigate("/login")
    }
  }

  return (
    <>
      <div style={{ width: "30vw", margin: "0 auto" }}>
        <Form onSubmit={handleSubmit} hasImage>
         

<div style={{display:"grid",gridTemplateRows:"1fr",gridRowGap:"20px"}}>
<ShortTextInput
            // label={`Store Name`}
            value={name}
            placeholder={`Enter Name`}
            setState={setName}
          />
          {/* <ShortTextInput
        label={`Slug`}
        value={slugify(name)}
        placeholder={`Slug`}
      /> */}
          <EmailInput
            // label={`Email`}
            value={email}
            placeholder={`Enter Email`}
            setState={setEmail}
          />
          <NumberInput
            // label={`Mobile`}
            value={mobile}
            placeholder={`Enter Mobile`}
            setState={setMobile}
          />
          <ShortTextInput
            // label={`Address`}
            value={address}
            placeholder={`Enter Address`}
            setState={setAddress}
          />
</div>
          


         

          <VStack id='test_id' align='flex-start' my={5} >
            <VStack
              pos='relative'
              w='80px'
              h='80px'
              justify='center'
              cursor='pointer'
              onClick={triggerImageModal}

            >
              {!imagePreview.preview && (
                <>
                  <FiUpload />
                  <Text textAlign={'center'} fontSize='smaller' fontWeight='600'>{"Upload Store Image" || 'Upload'}</Text>
                </>
              )}
              {renderImage()}
              <Box
                bg='#322A5D20'
                borderRadius='md'
                justify='center'
                transition='all 300ms'
                _hover={{
                  bg: '#322A5D50'
                }}
                pos='absolute'
                h='100%'
                w='100%'
              />
            </VStack>


            <input
              id={`fileInput${fieldId}`}
              type='file'
              className='file_upload_input'
              onChange={handleChange}
            />
          </VStack>
          {/* <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Store Image
      </ImageInput> */}
          <FormSubmitButton text="Create Store" />
        </Form>

      </div>
    </>
  );
}

export default AddStoreForm;
