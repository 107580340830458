import React, { useContext } from "react";
import Modal from "../../../../../../Partials/Elements/Modal/Modal";
import { AppContext } from "../../../../../../../AppContext/AppContext";
import ApproveItem from "../ApproveItem/ApproveItem";

export default function PaymentDetailsMOdal({
  showModal,
  handleClose,
  showApprove,
  targetID,
  setShowModal,
  setShowApprove,
}) {
  const { triggerFetch } = useContext(AppContext);
  console.log("PaymentDetailsMOdal -> triggerFetch", targetID);
  console.log(showApprove);

  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showApprove && `Approve Payment`}
          extraClass={"overflow-modal"}
        >
          {showApprove && (
            <ApproveItem
              api={"payment/approvePayment/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowApprove}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </div>
  );
}
