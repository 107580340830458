import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  PRODUCT_API,
  PRODUCT_SIZE_API,
  SINGLE_PRODUCT_API,
} from "../../../../../../Utilities/APIs/APIs";

import { AppContext } from "../../../../../../AppContext/AppContext";
import ProductSizeTable from "./components/ProductSizeTable/ProductSizeTable";
import ProductSizeModal from "./components/ProductSizeModal/ProductSizeModal";
import ProductSizeHeading from "./components/ProductSizeHeading";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
// import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";

function Size() {
  const { productId } = useParams();
  // const [search, setSearch] = useState("");
  const [productSizes, setProductSizes] = useState(null);
  const [productName, setProductName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { seller } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  // const navigate = useNavigate()
  useEffect(() => {
    async function fetchAndsetProductSizes() {
      const { data } = await axiosInstance.get(
        PRODUCT_SIZE_API + "getSizeByProductId/" + productId
      );
      setProductSizes(data);
    }
    fetchAndsetProductSizes();
  }, [axiosInstance, toggleFetch, productId]);

  useEffect(() => {
    async function fetchAndSetProductName() {
      const { data } = await axiosInstance.get(
        PRODUCT_API + SINGLE_PRODUCT_API + productId
      );
      setProductName(data.name);
    }
    fetchAndSetProductName();
  }, [axiosInstance, productId]);

  useEffect(() => console.log("Sizes:", productSizes), [productSizes]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <ProductSizeHeading
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
          productSizes={productSizes}
        />
        <ProductSizeTable
          productName={productName}
          productSizes={productSizes}
          setShowModal={setShowModal}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setTargetID={setTargetID}
          triggerFetch={triggerFetch}
          setShowViewSection={setShowViewSection}
        />
      </div>

      <ProductSizeModal
        showModal={showModal}
        handleClose={handleClose}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
        targetID={targetID}
        triggerFetch={triggerFetch}
        seller={seller}
        productId={productId}
        productName={productName}
        productSizes={productSizes}
      />
    </>
  );
}

export default Size;
