import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import { Chakra_Styles } from "../../../MyStore";
import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import ArchiveButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";

export default function ProductTable({
  type,
  products,
  setShowModal,
  setShowDeleteSection,
  setShowViewSection,
  setShowUpdateForm,
  setTargetID,
  setArchive,
  navigate,
  setSellerStoreProductId
}) {
  return (
    <div>
      <CRUDTable>
        <tbody>
          {products?.map((product) => (
            <CRUDTableRow key={product._id}>
             
              <ImageCell
                imgSrc={product?.product?.cardImage}
                style={{
                  height: "40px",
                }}
              />
              <ShortTextCell
                text={product?.product?.name}
                cellStyle={{
                  fontWeight: "600",
                }}
              />
              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={product?.product?._id}
                      setTargetID={setTargetID}
                    >
                      <Text
                        {...Chakra_Styles.actionMenuItem}
                        onClick={() => setTargetID(product?.product._id)}
                      >
                        Product Info
                      </Text>
                    </ViewButton>
                    {/* <CRUDButton
                      dataTip="Feature"
                      dataFor="Product Feature"
                      handleClick={() =>
                        navigate(
                          "/mystores/product/productScreen/" + product._id
                        )
                      }
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>
                        Manage Variations
                      </Text>
                    </CRUDButton> */}
                    {type !== "" && (
                      <EditButton
                        setShowModal={setShowModal}
                        setShowUpdateForm={setShowUpdateForm}
                        setTargetID={setTargetID}
                        targetID={product.product._id}
                        sellerStoreProduct={product._id}
                        setSellerStoreProductId={setSellerStoreProductId}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          Edit Product
                        </Text>
                      </EditButton>
                    )}

                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={product.product._id}
                      isArchive={product.product.isArchive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>
                        {product.product.isArchive
                          ? "Unarchive Product"
                          : "Archive Product"}
                      </Text>
                    </ArchiveButton>
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
