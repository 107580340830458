import "../Auth.css";
import LoginForm from "./components/LoginForm";
import LoginTop from "./components/LoginTop";
import LoginBottom from "./components/LoginBottom";
import LoginWrapper from "./LoginWrapper";

function Login() {
  return (
    <LoginWrapper>
      <LoginTop />
      <LoginForm />
      <LoginBottom />
    </LoginWrapper>
  );
}

export default Login;
