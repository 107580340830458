import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_API } from "../../../../Utilities/APIs/APIs";
import { VStack } from "@chakra-ui/react";
import SingleImageInput from "../../../Partials/Layouts/Forms/FormInputs/SingleImageInput/SingleImageInput";
import UpdateSizeAndGenderPrice from "./UpdateSizeAndGenderPrice/UpdateSizeAndGenderPrice";
import AddNewField from "./AddNewSize/AddNewField";
import SelectedSize from "./CreateProduct/components/SelectedSize";
import SelectProduct from "./SelectProduct";

function UpdateProduct({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  toggleFetch,
  storeId,
  type,
  sellerStoreProductId,
}) {
  const [recommendedItems, setRecommendedItems] = useState([]);
  const [name, setName] = useState("");

  const [isAPIBusy, setIsAPIBusy] = useState();
  const [gender, setGender] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [productCondition, setProductCondition] = useState("");

  const [newPrice, setNewPrice] = useState("");
  const [newSize, setNewSize] = useState("");
  const [newStock, setNewStock] = useState("");
  const [newGender, setNewGender] = useState("");
  const [newSelectedSize, setNewSelectedSize] = useState(null);
  const [newAllSelectedSizes, setNewAllSelectedSizes] = useState([]);

  const axiosInstance = useAxiosInstance();
  const [sellerStoreProductSizes, setSellerStoreProductSizes] = useState(null);

  useEffect(() => {
    async function fetchAndSellerStoreProductSizes() {
      const { data } = await axiosInstance.get(
        PRODUCT_API + "getSellerStoreProductSizesById/" + sellerStoreProductId
      );
      setSellerStoreProductSizes(data);
    }
    fetchAndSellerStoreProductSizes();
  }, [sellerStoreProductId]);

  const n_selected_size = JSON.stringify(newAllSelectedSizes);
  const rec_item = JSON.stringify(recommendedItems);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);
    const updatedData = {
      sellerStoreProductId: sellerStoreProductId,
      size: n_selected_size,
      price,
      stock,
      recommendedItems: rec_item,
      productCondition,
    };
    const response = await axiosInstance.patch(
      PRODUCT_API + "updateSellerStoreProductSize/",
      updatedData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }

    setIsAPIBusy(false);
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align="stretch">
        <SingleImageInput
          disabled={true}
          fieldId={2}
          state={
            sellerStoreProductSizes &&
            sellerStoreProductSizes[0]?.sellerStoreProduct?.product?.cardImage
          }
          allowUpdateImage
          children={"Card Image"}
        />
        <ShortTextInput
          value={
            sellerStoreProductSizes &&
            sellerStoreProductSizes[0]?.sellerStoreProduct?.product?.name
          }
          placeholder={`Enter Name`}
          setState={setName}
          disabled={true}
        />
        <UpdateSizeAndGenderPrice
          sellerStoreProductSizes={sellerStoreProductSizes}
          gender={gender}
          setGender={setGender}
          size={size}
          setSize={setSize}
          price={price}
          setPrice={setPrice}
          stock={stock}
          setStock={setStock}
          productCondition={productCondition}
          setProductCondition={setProductCondition}
        />
        <AddNewField
          size={size}
          type={type}
          sellerStoreProductSizes={sellerStoreProductSizes}
          newSize={newSize}
          setNewSize={setNewSize}
          newPrice={newPrice}
          setNewPrice={setNewPrice}
          newGender={newGender}
          setNewGender={setNewGender}
          newSelectedSize={newSelectedSize}
          setNewSelectedSize={setNewSelectedSize}
          newStock={newStock}
          setNewStock={setNewStock}
          newAllSelectedSizes={newAllSelectedSizes}
          setNewAllSelectedSizes={setNewAllSelectedSizes}
          productCondition={productCondition}
          setProductCondition={setProductCondition}
        />
        <SelectedSize
          allSelectedSizes={newAllSelectedSizes}
          setAllSelectedSizes={setNewAllSelectedSizes}
        />
        <SelectProduct
          setRecommendedItems={setRecommendedItems}
          recommendedItems={recommendedItems}
        />

        <FormSubmitButton isLoading={isAPIBusy} text="Update Product" />
      </VStack>
    </Form>
  );
}

export default UpdateProduct;
