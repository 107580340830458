import { useNavigate, useParams } from "react-router-dom";
import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import ShoeSizeThumb from "../../../../../Assets/shoe_size_thumb.png";
import ColorPallete from "../../../../../Assets/color_pallete.png";

function ProductNavLink() {
  const { productId } = useParams();
  const navigate = useNavigate();

  const NAV_OPTIONS = [
    {
      label: "Manage Sizes",
      description:
        "Create, edit and manage different sizes for your product - to allow customers a better shopping experience.",
      image: ShoeSizeThumb,
      link: `/mystores/products/size/${productId}`,
    },
    {
      label: "Manage Colors",
      description:
        "Manage different color variations of your product - be a pro seller by letting customers buy their favorite ones!",
      image: ColorPallete,
      link: `/mystores/products/color/${productId}`,
    },
  ];

  function goToPage(link) {
    navigate(link);
  }

  return (
    // <NavCardList numOfCards='five'>
    //   <NavCard
    //     cardName={`Products Size`}
    //     navCardLink={`/mystores/products/size/${productId}`}
    //   >
    //     <RiRuler2Line />
    //   </NavCard>

    //   <NavCard
    //     cardName={`Products Color`}
    //     navCardLink={`/mystores/products/color/${productId}`}
    //   >
    //     <RiPaintFill />
    //   </NavCard>
    // </NavCardList>
    <VStack w="full" align="stretch" h="full" pt="4rem">
      <HStack spacing="10" w="full" pb="5">
        <Text className="screen_heading" fontWeight="semibold">
          Manage Product Variations
        </Text>
      </HStack>
      <HStack
        flexWrap={"wrap"}
        w="full"
        h="max-content"
        align="stretch"
        spacing={"5"}
      >
        {NAV_OPTIONS.map((option, index) => (
          <VStack
            key={index}
            maxW="300px"
            h="auto"
            w="max-content"
            bg="white"
            borderRadius="md"
            boxShadow={"0px 0px 30px 4px rgba(0,0,0,0.05)"}
            align="flex-start"
            p="10"
            pr="20"
            spacing="10"
            cursor="pointer"
            transition="all 300ms"
            borderWidth="thin"
            borderColor="white"
            _hover={{
              color: "blue.400",
              borderColor: "blue.400",
            }}
            onClick={() => goToPage(option.link)}
          >
            <Image src={option.image} h="40px" objectFit="contain" />
            <VStack align="flex-start">
              <Text fontSize="medium" fontWeight="semibold">
                {option.label}
              </Text>
              <Text fontSize="small">{option.description}</Text>
            </VStack>
          </VStack>
        ))}
      </HStack>
    </VStack>
  );
}

export default ProductNavLink;
