import { useEffect, useState } from "react";
import { PRODUCT_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchProducts({ storeId, toggleFetch, type }) {
  const axiosInstance = useAxiosInstance();
  const [products, setProducts] = useState(null);

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(
        PRODUCT_API +
          `getSellerStoreProductByType?type=${type}&&storeId=${storeId}`
      );
      console.log(data);
      setProducts(data);
    }
    fetchAndSetProducts();
  }, [axiosInstance, toggleFetch, storeId, type]);

  console.log(products);
  return products;
}
