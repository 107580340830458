import { BsFillPlusCircleFill } from 'react-icons/bs'

import './CreateButton.css'
import { Button } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

function CreateButton ({
  screenTopicSingular,
  setShowModal,
  setShowCreateForm
}) {
  return (
    <Button
      colorScheme='ghost'
      // rightIcon={<HiLogout />}
      leftIcon={<FiPlus />}
      bg='#322A5D30'
      color={'#322A5D'}
      _hover={{
        bg: '#322A5D50'
      }}
      fontSize='small'
      justifyContent='flex-start'
      paddingY='18px'
      onClick={() => {
        setShowModal(true)
        setShowCreateForm(true)
      }}
    >
      New {screenTopicSingular}
    </Button>
    // <div className="btn_addnew_wrapper">
    //     <button
    //       className='btn_add_new'
    //       onClick={() => {
    //         setShowModal(true)
    //         setShowCreateForm(true)
    //       }}
    //     >
    //         <BsFillPlusCircleFill />
    //         {`Create New ${screenTopicSingular}`}
    //     </button>
    // </div>
  )
}

export default CreateButton
