import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import CreateButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function ProductColorHeading({
  productColor,
  setShowCreateForm,
  setShowModal,
}) {
  return (
    <HStack spacing="10" w="full" pb="5">
      <Text className="screen_heading" fontWeight="semibold">
        Product Colour ({productColor?.length} in total)
      </Text>
      <CreateButton
        screenTopicSingular={"Product Color"}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
      />
    </HStack>
  );
}
