import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { COLOUR_API } from "../../../../../../Utilities/APIs/APIs";

export default function useFetchColor({ toggleFetch, storeId }) {
  const axiosInstance = useAxiosInstance();
  const [colors, setColors] = useState([]);
  useEffect(() => {
    async function fetchAndSetColors() {
      const { data } = await axiosInstance.get(COLOUR_API);
      setColors(data);
    }
    fetchAndSetColors();
  }, [axiosInstance, toggleFetch, storeId]);

  return colors;
}
