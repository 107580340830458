import { useEffect, useState } from "react";


function SelectPrice({ price, setPrice }) {

  const [inputValue, setInputValue] = useState(0);

  // Sync inputValue with price prop when it changes
  useEffect(() => {
    setInputValue(price);
  }, [price]);

  const handleChange = (e) => {
    const newPrice = e.target.value;
    setInputValue(newPrice);
    setPrice(newPrice);
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <span
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          fontSize: "1.2rem",
          color: "#555",
        }}
      >
        $
      </span>
      <input
        type="number"
        placeholder="Enter Price"
        value={inputValue}
        onChange={handleChange}
        style={{
          width: "100%",
          minHeight: "4rem",
          borderRadius: "5px",
          border: "1px solid #ccc",
          padding: "0.5rem",
          paddingLeft: "2rem", // Add padding to the left to make space for the dollar sign
        }}
      />
    </div>

  )
}

export default SelectPrice