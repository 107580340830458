import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function RegTopTextInfo({ name, email }) {
  return (
    <div>
      <VStack h="120px" justify={"center"} align={"flex-start"} pl="3">
        <Text
          fontSize={"20px"}
          fontWeight={"bold"}
          color="#5c5c5c"
          maxW="full"
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {name
            ? `Welcome, ${
                name.includes(" ")
                  ? name.split(" ")[0]
                  : name.length > 10
                  ? name.substring(0, 10) + "..."
                  : name
              }`
            : `Start selling with CheB`}
        </Text>
        <Box h="1px" w="full" bg="gray.300" />
        <Text fontSize="small">
          {email ? (
            <>
              Once completed, you can login using your email: <b>{email}</b>
            </>
          ) : (
            `Get onboarded now and multiply your sneaker sales!`
          )}
        </Text>
      </VStack>
    </div>
  );
}
