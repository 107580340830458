import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import "./ApproveItem.css";

function ApproveItem({
  api,
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  async function handleDelete(id) {
    const time = new Date().toLocaleString();
    const res = await axiosInstance.patch(api + id, {
      isApprove: true,
      dateOfApproval: time,
    });
    console.log(res);

    if (res.data) {
      setShowModal(false);
      setShowDeleteSection(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to Approve this Payment.</p>
        <div className="approve_container">
          <button onClick={() => handleDelete(targetID)} className="approve">
            Approve
          </button>

          <button
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApproveItem;
