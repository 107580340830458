import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./Partials/Sections/SideBar/SideBar";
import MainScreen from "../Components/Screens/MainScreen/MainScreen";
import { AppContext } from "../AppContext/AppContext";

import "./App.css";

function App() {
  const { seller } = useContext(AppContext);

  return (
    <>
      <BrowserRouter>
        <main className={`main ${seller ? "seller" : "auth"}`}>
          {seller && <Sidebar />}
          <MainScreen seller={seller} />
        </main>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
