import { useLocation } from "react-router-dom";
import { VStack } from "@chakra-ui/react";

import SidebarTop from "./SidebarTop/SidebarTop";
import SidebarItems from "./SidebarItems/SidebarItems";

import "./SideBar.css";

function Sidebar() {
  const { pathname } = useLocation();
  const isRegister = pathname.includes("/register");

  return (
    <div className="sidebar">
      {isRegister ? (
        ""
      ) : (
        <VStack className="sidebar_inner">
          <SidebarTop />
          <SidebarItems />
        </VStack>
      )}
    </div>
  );
}
export default Sidebar;
