import { useEffect, useState } from "react";
import { SIZE_API } from "../../../../../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchSize({ targetID }) {
  const axiosInstance = useAxiosInstance();
  const [sizeInfo, setSizeInfo] = useState(null);

  useEffect(() => {
    async function fetchAndSetSizeInfo() {
      const { data } = await axiosInstance.get(
        SIZE_API + "getSingleSize/" + targetID
      );
      setSizeInfo(data);
    }
    fetchAndSetSizeInfo();
  }, [targetID, axiosInstance]);

  return sizeInfo;
}
