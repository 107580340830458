import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosAuthInstance from '../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance';
import { AppContext } from '../../../../AppContext/AppContext';
// import AppContext from '../../../../AppContext/AppContext';

function VerifyEmail() {

    const { token } = useParams();
    const axiosAuthInstance = useAxiosAuthInstance();
    const navigate = useNavigate();
    const { setSeller } = useContext(AppContext);

    useEffect(() => {
        if (token) {
          (async function () {
            const verification = await axiosAuthInstance.patch("verify/" + token);
            if (verification.data.isVerified === true) {
                localStorage.setItem("seller", JSON.stringify(verification.data));
                setSeller(verification.data);
                navigate("/dashboard");
            }
          })();
        }
    }, [token, axiosAuthInstance, navigate, setSeller]);


    return (
        <div>VerifyEmail</div>
    )
}

export default VerifyEmail