import { useState } from "react";

import PaymentDetailsHedling from "./components/PaymentDetailsHedling";
import PaymentDetailsTable from "./components/PaymentDetailsTable/PaymentDetailsTable";
import PaymentDetailsMOdal from "./components/PaymentDetailsModal/PaymentDetailsMOdal";

function PaymentDetails({ approvePaymentCount, allPaymentsToSeller }) {
  const [showModal, setShowModal] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [targetID, setTargetID] = useState(null);

  function handleClose() {
    setShowModal(false);
    setShowApprove(false);
  }

  return (
    <>
      <div className="crud_holder">
        <PaymentDetailsHedling approvePaymentCount={approvePaymentCount} />
        <PaymentDetailsTable
          allPaymentsToSeller={allPaymentsToSeller}
          setShowModal={setShowModal}
          setShowApprove={setShowApprove}
          setTargetID={setTargetID}
        />
      </div>

      <PaymentDetailsMOdal
        showModal={showModal}
        handleClose={handleClose}
        showApprove={showApprove}
        targetID={targetID}
        setShowModal={setShowModal}
        setShowApprove={setShowApprove}
      />
    </>
  );
}

export default PaymentDetails;
