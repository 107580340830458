import React from "react";
import Select from "react-select";

export default function SelectProductCondition({
  size,
  type,
  productCondition,
  setProductCondition,
}) {
  const options = [
    { value: "New", label: "New" },
    { value: "Pre-owned", label: "Pre-owned" },
  ];

  return (
    <div style={{ width: "100%" }}>
      {type === "sneaker" && size !== null && (
        <>
          <Select
            placeholder={"Condition"}
            isMulti={false}
            defaultOptions={options}
            options={options}
            value={productCondition}
            onChange={(e) => {
              setProductCondition(e);
            }}
          />
        </>
      )}
    </div>
  );
}
