import React from "react";
import { RiSearchLine } from "react-icons/ri";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function ProductColorHeading({
  colors,
  search,
  setSearch,
  setShowCreateForm,
  setShowModal,
}) {
  return (
    <div>
      <div className="screen_header">
        <h1 className="screen_heading">Color ({colors?.length} in total)</h1>
        <div>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div>

        <CreateButton
          screenTopicSingular={"Color"}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
      </div>
    </div>
  );
}
