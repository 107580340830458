import { Text } from "@chakra-ui/react";
import { AppContext } from "../../../../../../AppContext/AppContext";
import { useContext } from "react";

export default function SidebarInfo() {
  const { seller } = useContext(AppContext);

  return (
    <><Text fontSize="medium" pt="15px">
    Welcome,
  </Text>
  <Text fontSize="x-large" fontWeight="semibold">
    {seller ? seller.name : ""}
  </Text></>
  )
}
