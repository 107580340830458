import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { STORE_API } from "../../../../Utilities/APIs/APIs";

export default function useFetchStore({ seller, filter, toggleFetch }) {
  const axiosInstance = useAxiosInstance();
  const [stores, setStores] = useState(null);

  useEffect(() => {
    async function fetchAndSetStores() {
      const { data } = await axiosInstance.get(
        STORE_API + `/getSellerAllstore/${seller?._id}?filter=${filter}`
      );
      setStores(data);
    }
    fetchAndSetStores();
  }, [axiosInstance, toggleFetch, seller?._id, filter]);

  return stores;
}
