import React from "react";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import ViewOrderDetails from "../ViewOrderDetails/ViewOrderDetails";
import OrderDelivered from "../OrderDelivered/OrderDelivered";
import CancelOrder from "../../CancelOrder/CancelOrder";

export default function OrderModal({
  showModal,
  handleClose,
  showViewSection,
  showProcessingOrder,
  showCancelOrder,
  setShowModal,
  setShowProcessingOrder,
  setShowCancelOrder,
  targetID,
  orderStatus,
  seller,
  triggerFetch,
  handleStatusChange,
}) {
  return (
    <div>
      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order Details`}
        >
          <ViewOrderDetails targetID={targetID} />
        </Modal>
      )}

      {showModal && showProcessingOrder && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            showProcessingOrder &&
            ((orderStatus === "processing" && "Delivered This Order") ||
              (orderStatus === "toBeDelivered" && "Shipped This Order") ||
              (orderStatus === "shipped" && "Completed This Order"))
          }
        >
          <OrderDelivered
            handleStatusChange={handleStatusChange}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowProcessingOrder={setShowProcessingOrder}
          />
        </Modal>
      )}
      {showModal && showCancelOrder && (
        <Modal
          handleClose={handleClose}
          modalHeading={showCancelOrder && `Cancel Order`}
        >
          <CancelOrder
            targetID={targetID}
            seller={seller}
            setShowModal={setShowModal}
            setShowCancelOrder={setShowCancelOrder}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}
