import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  COLOUR_API,
  PRODUCT_COLOUR_API,
} from "../../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateProductColor({
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
}) {
  const [color, setColor] = useState("");
  const [colors, setColors] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductName() {
      const { data } = await axiosInstance.get(PRODUCT_COLOUR_API + targetID);
      setColor(data.color._id);
    }
    fetchAndSetProductName();
  }, [axiosInstance, targetID]);

  useEffect(() => {
    async function fetchAndSetColors() {
      const { data } = await axiosInstance.get(COLOUR_API);
      setColors(data);
    }
    fetchAndSetColors();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("color", color);

    const response = await axiosInstance.patch(
      PRODUCT_COLOUR_API + targetID,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <SelectInput label={"Color"} value={color} setState={setColor}>
        <SelectOption optionValue="" optionText="Select Color" />
        {colors?.map((color) => (
          <SelectOption
            key={color._id}
            optionValue={color._id}
            optionText={color?.name}
          />
        ))}
      </SelectInput>
      

      <FormSubmitButton text="Update Color" />
    </Form>
  );
}

export default UpdateProductColor;
