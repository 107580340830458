import { VStack, Box, Heading, Text} from "@chakra-ui/react";

function Tips() {
  const tipsData = [
    {
      heading: "Top View",
      content: "Capture a photo from directly above the sneakers. This showcases the design on the top, laces, and toe box.",
    },
    {
      heading: "Side View",
      content: "Take a picture from the side of the sneakers. If possible, provide both left and right side views. This highlights the side profile, brand logos, and unique side features.",
    },
    {
      heading: "Back View",
      content: "A photo from the back shows the heel counter, branding, and any special designs on the back.",
    },
    {
      heading: "Front View",
      content: "Photograph the sneakers from the front to display the toe cap shape, lacing system, and front design.",
    },
    {
      heading: "Bottom View",
      content: "Capture the sole pattern, brand logo, and wear condition by photographing the bottom of the sneakers.",
    },
    {
      heading: "Inside View",
      content: "Take a photo looking into the sneaker from above, with the tongue pulled forward or to the side if possible. This angle should highlight the insole, the interior material, branding inside the shoes.",
    },
  ];

  return (
    <VStack spacing={8} align="stretch" p={8} maxW="600px" mx="auto">
    
      <Box>
        <Text mb={4}>
          To ensure our sneaker database is as detailed and useful as possible, please capture images of your sneakers from the following angles. These photos will help fellow sneaker enthusiasts appreciate the design, condition, and unique features of each pair.
        </Text>
        <VStack align="stretch" spacing={4}>
          {tipsData.map((tip, index) => (
            <Box key={index} borderWidth="1px" borderRadius="lg" p={4}>
              <Heading as="h2" size="md" mb={2}>{tip.heading}</Heading>
              <Text>{tip.content}</Text>
            </Box>
          ))}
        </VStack>
      </Box>
      <Box>
        <Heading as="h1" size="lg" mb={4}>Tips for Great Photos</Heading>
        <Text mb={4}>
          Use good, natural lighting to ensure details are visible without harsh shadows. Place sneakers against a clean, neutral background for consistency and to make details stand out. Ensure your camera is focused and the sneakers occupy most of the frame for each photo.
        </Text>
      </Box>
      <Box>
        <Heading as="h1" size="lg" mb={4}>Tips for Capturing the Inside View</Heading>
        <Text mb={4}>
          Ensure there's enough light shining inside the sneaker to illuminate the interior details. Using a flashlight or taking photos in well-lit conditions can help. If the insole is removable and features special designs or branding, consider taking an additional photo of the insole next to the sneaker. Try to smooth out and straighten the tongue and sides of the sneaker for a clear view inside. If there are details under the tongue or on the sides of the interior that are unique, consider taking additional close-up shots to showcase these features.
        </Text>
      </Box>
    </VStack>
  );
}

export default Tips;
