import React from "react";
import FormWrapper from "../../../FormWrapper";
import { Link } from "react-router-dom";

export default function EmailSentInfo({ email, handleResend }) {
  return (
    <FormWrapper>
      <h3>Check your email</h3>
      <p className="auth_status">
        If we found an account with <span>{email ? email : ""}</span>, an email
        has been sent. Please check your email in a moment.
      </p>
      <div className="dha_box" style={{ marginTop: "0" }}>
        <p className="body_text">Didn’t receive a link?</p>
        <button className="resend_btn" onClick={handleResend}>
          resend email
        </button>
      </div>
      <Link to="/login" className="btn_text forgot_pass">
        Back to Login
      </Link>
    </FormWrapper>
  );
}
