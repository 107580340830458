import { useState, useContext, useEffect } from "react";

// import "./Mystore.css";
import { VStack } from "@chakra-ui/react";

import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { AppContext } from "../../../AppContext/AppContext";

import PaymentCardHeader from "./components/PaymentCardHeader";
import PaymentCardInfoTable from "./components/PaymentCardInfoTable";
import PaymentInfoModal from "./components/PaymentInfoModal/PaymentInfoModal";

function PaymentCardInfo() {
  // const [search, setSearch] = useState("");
  const [paymentInfoItems, setPaymentInfoItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { seller } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetStores() {
      const { data } = await axiosInstance.get(`cardInfo/`);
      console.log("card info:", data);
      setPaymentInfoItems(data);
    }

    fetchAndSetStores();
  }, [axiosInstance, toggleFetch, seller?._id]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <VStack w="full" align="stretch">
        <PaymentCardHeader
          paymentInfoItems={paymentInfoItems}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
        <PaymentCardInfoTable
          paymentInfoItems={paymentInfoItems}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
          setShowUpdateForm={setShowUpdateForm}
        />
      </VStack>

      <PaymentInfoModal
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showViewSection={showViewSection}
        showModal={showModal}
        targetID={targetID}
        handleClose={handleClose}
        triggerFetch={triggerFetch}
        seller={seller}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
        setShowUpdateForm={setShowUpdateForm}
      />
    </>
  );
}

export default PaymentCardInfo;
