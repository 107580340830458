import { useEffect, useState } from "react";
import { NumberInput, VStack } from "@chakra-ui/react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function EditPaymentCardInfo({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  toggleFetch,
}) {
  // {bankName,accountHolderName,accountNumber,routingNumber,mobile,email,bankAddress

  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [isAPIBusy, setIsAPIBusy] = useState();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetCardInfo() {
      const { data } = await axiosInstance.get("cardInfo/" + targetID);
      setBankName(data.bankName);
      setAccountHolderName(data.accountHolderName);
      setAccountNumber(data.accountNumber);
      setRoutingNumber(data.routingNumber);
      setMobile(data.mobile);
      setEmail(data.email);
      setBankAddress(data.bankAddress);
    }
    fetchAndsetCardInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);

    const itemData = {
      bankName,
      accountHolderName,
      accountNumber,
      routingNumber,
      mobile,
      email,
      bankAddress,
    };

    const response = await axiosInstance.patch(
      "cardInfo/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <ShortTextInput
          value={accountHolderName}
          placeholder={`Enter Account Holder Name`}
          setState={setAccountHolderName}
        />
        <ShortTextInput
          value={bankName}
          placeholder={`Enter Bank Name`}
          setState={setBankName}
        />
        <NumberInput
          value={accountNumber}
          placeholder={`Enter Account Number`}
          setState={setAccountNumber}
        />
        <NumberInput
          value={routingNumber}
          placeholder={`Enter Routing Number`}
          setState={setRoutingNumber}
        />
        <ShortTextInput
          value={bankAddress}
          placeholder={`Enter Bank Address`}
          setState={setBankAddress}
        />
        <EmailInput
          value={email}
          placeholder={`Enter Email`}
          setState={setEmail}
        />
        <NumberInput
          value={mobile}
          placeholder={`Enter Mobile`}
          setState={setMobile}
        />

        <FormSubmitButton isLoading={isAPIBusy} text="Update Card Info" />
      </VStack>
    </Form>
  );
}

export default EditPaymentCardInfo;
