import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_SIZE_API } from "../../../../../../../Utilities/APIs/APIs";

function ViewProductSize({ productName, targetID }) {
  const [size, setSize] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetProductName() {
      const { data } = await axiosInstance.get(
        PRODUCT_SIZE_API + "getSingleProductSize/" + targetID
      );
      setSize(data.size.name);
    }
    fetchAndSetProductName();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      <h1>Product</h1>
      <p>{productName}</p>

      <h1>Size</h1>
      <p>{size}</p>

      {/* <h1>Quantity</h1>
           <p>{size}</p> */}
    </div>
  );
}

export default ViewProductSize;
