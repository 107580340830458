import React from "react";

export default function ShowSizeAndGender({ item }) {
  return (
    <>
      <input
        type="text"
        className="price_input"
        value={item.gender}
        readOnly
        style={{ cursor: "not-allowed" }}
      />
      <input
        type="text"
        placeholder="Size"
        value={item?.size.name}
        className="price_input"
        readOnly
        style={{ cursor: "not-allowed" }}
      />
    </>
  );
}
