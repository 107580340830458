import Select from "react-select";
import AsyncSelect from "react-select/async";
import useFetchSize from "../CreateProduct/Hooks/useFetchSize";

import "./AddNewField.css";
import { RiAddFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import SelectProductCondition from "../CreateProduct/components/SelectProductCondition";

export default function AddNewField({
  type,
  size,
  newPrice,
  setNewPrice,
  newStock,
  setNewStock,
  newGender,
  setNewGender,
  newSize,
  setNewSize,
  setNewSelectedSize,
  newSelectedSize,
  newAllSelectedSizes,
  setNewAllSelectedSizes,
  sellerStoreProductSizes,
  setProductCondition,
  productCondition,
}) {
  const options = [
    { value: "men", label: "Men" },
    { value: "women", label: "Women" },
  ];

  const sizes = useFetchSize({ type, gender: newGender });

  function handleSizeChange(e) {
    console.log(sellerStoreProductSizes);
    const selectedSize = e.value;
    const existingSizes = sellerStoreProductSizes.map((size) => size?.size._id);

    if (existingSizes.includes(selectedSize)) {
      return toast.error("Size already exists");
    }

    setNewSize(e);
    setNewSelectedSize(selectedSize);
  }

  function add() {
    const nSize = {
      ...newSize,
      stock: newStock,
      gender: newGender,
      price: newPrice,
      productCondition: productCondition.value,
    };

    const existingSize = newAllSelectedSizes.find(
      (size) => size.value === newSelectedSize
    );
    if (existingSize) {
      return toast.error("Size already exists");
    }

    setNewAllSelectedSizes([...newAllSelectedSizes, nSize]);
    setNewSize("");
    setNewPrice("");
    setNewStock("");
    setNewSelectedSize(null);
    setProductCondition("");
  }

  console.log(productCondition);
  return (
    <div>
      <h3 className="field_label">Add New Sizes</h3>
      <div className="field_container">
        <Select
          options={options}
          onChange={(e) => setNewGender(e.value)}
          className="select_input"
        />
        <AsyncSelect
          placeholder={"Choose Sizes"}
          className="select_input"
          isMulti={false}
          defaultOptions={sizes}
          value={newSize}
          onChange={(e) => {
            handleSizeChange(e);
          }}
        />
        <SelectProductCondition
          size={size}
          type={type}
          productCondition={productCondition}
          setProductCondition={setProductCondition}
        />
        <input
          type="number"
          placeholder="price"
          className="price_input"
          value={newPrice}
          onChange={(e) => setNewPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="stock"
          className="price_input"
          value={newStock}
          onChange={(e) => setNewStock(e.target.value)}
        />
        <button
          type="button"
          onClick={() => {
            add();
          }}
          className={`add_size_button ${
            newPrice === "" || newStock === "" || !newSelectedSize
              ? "disabled"
              : ""
          }`}
          disabled={newPrice === "" || newStock === "" || !newSelectedSize}
        >
          <RiAddFill size={18} />
        </button>
      </div>
    </div>
  );
}
