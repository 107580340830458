import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";

function ViewOrderDetails({ targetID }) {
  const [orderInfo, setorderInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetorderInfo() {
      const { data } = await axiosInstance.get(ORDERS_API + "/" + targetID);
      setorderInfo(data);
    }
    fetchAndsetorderInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {orderInfo && (
        <>
          <h1>Customer Info</h1>
          <p>Name: {orderInfo.customer?.name}</p>
          <p>Email: {orderInfo.customer?.email}</p>
          <p>Mobile: {orderInfo.customer?.mobile}</p>

          <h1>Address Info</h1>
          <p>City: {orderInfo.address?.city}</p>
          <p>State: {orderInfo.address?.state}</p>
          <p>Street: {orderInfo.address?.street}</p>

          <h1>Order Info</h1>
          <p>Processing Fee: {orderInfo.processingFee}</p>
          <p>Shipping Fee: {orderInfo.shippingFee}</p>
          <p>Tax: {orderInfo.tax}</p>
          <p>Subtotal: {orderInfo.subTotal}</p>
          <p>Total: {orderInfo.total}</p>

         
        </>
      )}
    </div>
  );
}

export default ViewOrderDetails;
