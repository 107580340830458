import React from "react";
import InputBoilerplate from "../FormInputs/InputBoilerplate";

export default function TimeInput({
  label,
  value,
  placeholder,
  setState,
  step,
}) {
  return (
    <InputBoilerplate
      label={label}
      type={"time"}
      value={value}
      step={step}
      placeholder={placeholder}
      setState={setState}
    />
  );
}
