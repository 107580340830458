import { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../../AppContext/AppContext";
import useFetchSize from "./Hooks/useFetchSize";
import ProductSizeHeading from "./components/ProductSizeHeading/ProductSizeHeading";
import ProductSizeTable from "./components/ProductSizeTable/ProductSizeTable";
import ProductSizeModal from "./components/ProductSizeModal/ProductSizeModal";

function ProductSize() {
  const { storeId } = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { seller } = useContext(AppContext);

  const sizes = useFetchSize({ toggleFetch, storeId });

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <ProductSizeHeading
          search={search}
          sizes={sizes}
          setSearch={setSearch}
          setShowModal={setShowModal}
          setShowCreateForm={setShowCreateForm}
        />
        <ProductSizeTable
          sizes={sizes}
          setShowModal={setShowModal}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setShowViewSection={setShowViewSection}
          setTargetID={setTargetID}
          search={search}
        />
      </div>

      <ProductSizeModal
        showModal={showModal}
        handleClose={handleClose}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setShowDeleteSection={setShowDeleteSection}
        targetID={targetID}
        seller={seller}
        storeId={storeId}
        triggerFetch={triggerFetch}
        setShowModal={setShowModal}
      />
    </>
  );
}

export default ProductSize;
