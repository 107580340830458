import CRUDButton from '../CRUDButton';
import { RiDraftLine } from 'react-icons/ri';
import './EditButton.css';

function EditButton({children, setShowModal, setShowUpdateForm, setTargetID, targetID, sellerStoreProduct, setSellerStoreProductId}) {
  return (
    <CRUDButton
    dataTip='Edit'
    dataFor='Edit Itemd'
      handleClick={() => {
        setShowModal(true)
        setShowUpdateForm(true)
        setTargetID(targetID)
        setSellerStoreProductId(sellerStoreProduct)
      }}
    >
        {children}
    </CRUDButton>
  )
}

export default EditButton