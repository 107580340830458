// export const BASE_URL =process.env.REACT_APP_BACKEND_URL
export const IMAGE_URL = process.env.REACT_APP_SPACES_URL;
export const PRODUCT_ALL_IMAGE = process.env.REACT_APP_SPACES_URL;

//product api

export const PRODUCT_API = "product/";
// export const PRODUCT_API ="/product"
export const SINGLE_PRODUCT_API = "getSingleProduct/";
export const UPDATE_PRODUCT_API = "updateSingleProduct/";
export const ALL_PRODUCT_BY_TYPE_API = PRODUCT_API + "getAllProductByType/";

// store api

export const STORE_API = "store/";
export const ADD_STORE_API = "add-store/";
export const ALL_STORE_API = "/getAllStore";
export const SINGLE_STORE_API = "getSellerSinglestore/";
export const UPDATE_STORE_API = "updateStore/";

export const SELLER_API = "seller/";

export const SIZE_API = "size/";
export const PRODUCT_SIZE_API = "product/productSize/";

export const COLOUR_API = "colour/";
export const PRODUCT_COLOUR_API = "product/productColor/";

export const ORDERS_API = "orders";
export const PAYMENTS_API = "payment/";
