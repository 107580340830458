import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";

import ViewItems from "./ViewItems/ViewItems";
import OrderItemsTable from "./components/OrderItemsTable";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import OrderItemsHeading from "./components/OrderItemsHeading";

function OrderItems() {
  const { orderId } = useParams();
  const [orderItems, setOrderItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetOrderItems() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "/items/" + orderId
      );
      setOrderItems(data);
    }
    fetchAndsetOrderItems();
  }, [axiosInstance, orderId]);

  function handleClose() {
    setShowModal(false);
  }
  return (
    <>
      <div className="crud_holder">
        <OrderItemsHeading orderItems={orderItems} />
        <OrderItemsTable
          orderItems={orderItems}
          setShowModal={setShowModal}
          setShowViewSection={setShowViewSection}
          setTargetID={setTargetID}
        />
      </div>

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order Item Details`}
        >
          <ViewItems targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default OrderItems;
