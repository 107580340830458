import { FiEye } from "react-icons/fi";
import CRUDButton from "../CRUDButton";

import "./ViewButton.css";

function ViewButton({
  children,
  setShowModal,
  setShowViewSection,
  setTargetID,
  targetID,
}) {
  return (
    <CRUDButton
      dataTip="View"
      dataFor="View Item"
      handleClick={() => {
        setShowModal(true);
        setTargetID(targetID);
        setShowViewSection(true);
      }}
    >
      {children}
      {/* <FiEye /> */}
    </CRUDButton>
  );
}

export default ViewButton;
