import React from "react";
import FormWrapper from "../../../FormWrapper";
import { Link } from "react-router-dom";

export default function EmailSentInput({ email, setEmail, handleSubmit }) {
  return (
    <div>
      <FormWrapper>
        <h3>Recover Password</h3>
        <form action="/">
          <div className="input_group">
            <label className="input_field_label caption bold">Email</label>
            <input
              type="email"
              className="input_field body_text"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <p className="status">We’ll email you a link that will let you change your password</p> */}
          </div>
          <button
            type="submit"
            className="btn_text submit"
            onClick={handleSubmit}
          >
            send recovery email
          </button>
        </form>
        <Link to="/login" className="btn_text forgot_pass">
          Back to Login
        </Link>
      </FormWrapper>
    </div>
  );
}
