import { useState } from 'react'
import UploadThumb from '../../../../../../Assets/img_upload.svg'

import './BannerImageInput.css'

function BannerImageInput({ fieldId, state, setState, allowCreateImage, allowUpdateImage, children }) {
  //for image preview
  const [imagePreview, setImagePreview] = useState({ preview: '' })
  const handleChange = (e) => {
    setState(e.target.files[0])
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0])
    })
  }

  return (
    <>
      <label className='upload_file_wrapper' htmlFor={`fileInput${fieldId}`}>
        <p className='input_field_label' style={{fontSize:"14px",color:"gray"}}>{children}</p>
        <div className="banner_preview_image">
          {allowUpdateImage && state &&
            <img src={imagePreview.preview ? imagePreview.preview : process.env.REACT_APP_SPACES_URL + state} alt="default thumb" />
          }


          {allowCreateImage &&
            <img src={imagePreview.preview ? imagePreview.preview : UploadThumb} alt="default thumb" />
          }
        </div>
      </label>

      <input
        id={`fileInput${fieldId}`}
        type='file'
        className='file_upload_input'
        onChange={handleChange}
      />
    </>
  )
}

export default BannerImageInput