import { Box, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FiUpload } from "react-icons/fi";

export default function RenderImageBox({ imagePreview, triggerImageModal }) {
  function renderImage() {
    return (
      <>
        {imagePreview.preview && (
          <Image
            objectFit="cover"
            w="120px"
            h="120px"
            src={imagePreview.preview}
          />
        )}
      </>
    );
  }
  return (
    <div>
      <VStack
        pos="relative"
        minW="120px"
        h="120px"
        justify="center"
        align={"flex-start"}
        cursor="pointer"
        onClick={triggerImageModal}
        borderRadius={"full"}
        overflow={"hidden"}
        role="group"
      >
        {!imagePreview.preview && (
          <VStack justify={"center"} w="full">
            <FiUpload />
            <Text textAlign={"center"} fontSize="smaller" fontWeight="600">
              {"Avatar"}
            </Text>
          </VStack>
        )}
        {renderImage()}
        <Box
          bg="#322A5D20"
          pos="absolute"
          top="0"
          left="0"
          h="full"
          w="full"
          transition="all 300ms"
          _groupHover={{
            bg: "#322A5D50",
          }}
        />
      </VStack>
    </div>
  );
}
