import React from "react";
import CRUDTable from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import DeleteButton from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import { Chakra_Styles } from "../../../../../MyStore";

export default function ProductSizeTable({
  productSizes,
  productName,
  setShowModal,
  setShowDeleteSection,
  setTargetID,
}) {
  return (
    <div>
      <CRUDTable>
        {/* <CRUDTableHeader>
            <CRUDth th='Product Name' />
            <CRUDth th='Size' />
            <CRUDth th='Actions' />
          </CRUDTableHeader> */}
        <tbody>
          {productSizes?.map((size) => (
            <CRUDTableRow key={size?._id}>
              <ShortTextCell text={productName} />
              <ShortTextCell text={size.size?.name} />
              {/* <ShortTextCell text={size.size?.name} /> */}
              {/* <td className='action_button_cell'> */}
              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    {/* <EditButton
                        setShowModal={setShowModal}
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={size._id}
                        setTargetID={setTargetID}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>Edit</Text>
                      </EditButton> */}
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={size._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>Delete</Text>
                    </DeleteButton>
                    {/* <CRUDButton
                    handleClick={() => navigate("/sizeAsset/" + size._id)}
                  >
                    <RiImage2Line />
                  </CRUDButton> */}
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
