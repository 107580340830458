import { VStack } from "@chakra-ui/react";
import SidebarImage from "./components/SidebarImage";
import SidebarInfo from "./components/SidebarInfo";

function SidebarTop() {
  return (
    <div className="logo_menu_wrapper">
      <VStack align="flex-start" className="top_logo_wrapper" spacing="0">
        <SidebarImage />
        <SidebarInfo />
      </VStack>
    </div>
  );
}

export default SidebarTop;
