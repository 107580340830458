import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import CreateButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function ProductSizeHeading({
  productSizes,
  setShowCreateForm,
  setShowModal,
}) {
  return (
    <div>
      <HStack spacing="10" w="full" pb="5">
        <Text className="screen_heading" fontWeight="semibold">
          Product Size ({productSizes?.length} in total)
        </Text>
        <CreateButton
          screenTopicSingular={"Size"}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
      </HStack>
    </div>
  );
}
