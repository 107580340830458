import React from "react";
import Modal from "../../../../../../../Partials/Elements/Modal/Modal";
import CreateProductColor from "../CreateProductColor";
import UpdateProductColor from "../UpdateProductColor";
import DeleteItem from "../../../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import { PRODUCT_COLOUR_API } from "../../../../../../../../Utilities/APIs/APIs";
import ViewProductColor from "../ViewProductColor";

export default function ProductColorModal({
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowModal,
  setShowDeleteSection,
  targetID,
  triggerFetch,
  seller,
  productId,
  productName,
}) {
  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product Colour`) ||
            (showUpdateForm && `Update Product Colour `) ||
            (showDeleteSection && `Delete Product Colour`)
          }
          extraClass={"overflow-modal"}
        >
          {showCreateForm && (
            <CreateProductColor
              seller={seller}
              productId={productId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateProductColor
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={PRODUCT_COLOUR_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product Color`}
        >
          <ViewProductColor productName={productName} targetID={targetID} />
        </Modal>
      )}
    </div>
  );
}
