import { Input } from "@chakra-ui/react";
import "./InputBoilerplate.css";

function InputBoilerplate({
  label,
  type,
  value,
  placeholder,
  setState,
  inputIsTextArea,
  setError,
  disabled,
  step,
}) {
  return (
    <div className="input_group">
      {label && (
        <label className="input_field_label caption bold">{label}</label>
      )}
      {!inputIsTextArea && (
        <Input
          // className='input_field'
          bg="white"
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setState(e.target.value);
            setError && setError("");
          }}
          size="lg"
          py="4"
          h="auto"
          fontSize="small"
          step={step}
          borderColor={"gray.300"}
          isDisabled={disabled}
        />
      )}
      {inputIsTextArea && (
        <textarea
          className="input_field"
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setState(e.target.value);
            setError && setError("");
          }}
        />
      )}
    </div>
  );
}

export default InputBoilerplate;
