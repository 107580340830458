import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";

import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import Tips from "../Tips/Tips";
import SearchProduct from "../SearchProduct"; // Import the new SearchProduct component

import { PRODUCT_API } from "../../../../../Utilities/APIs/APIs";
import SelectProduct from "../SelectProduct";
import { VStack } from "@chakra-ui/react";

import "./CreateProduct.css";
import ShowSelectedProduct from "./components/ShowSelectedProduct";
import SelectSizeAndGender from "./components/SelectSizeAndGender/SelectSizeAndGender";

function CreateProduct({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  storeId,
  type,
}) {
  const axiosInstance = useAxiosInstance();

  const [selectedSize, setSelectedSize] = useState("");
  const [productCondition, setProductCondition] = useState("");

  const [showTips, setShowTips] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [recommendedItems, setRecommendedItems] = useState([]);
  const [product, setProduct] = useState(null);
  const [size, setSize] = useState(null);
  const [allSelectedSizes, setAllSelectedSizes] = useState([]);
  const [gender, setGender] = useState("");

  const handleProductSelect = async (selectedOption, setFieldValue) => {
    try {
      const response = await axiosInstance.get(
        PRODUCT_API + `getSingleProductAllItem/${selectedOption.value}`
      );
      const { productData } = response.data;
      setProduct(productData._id);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load product data");
    }
  };

  function handleClose() {
    setShowTips(false);
    setShowCreateForm(true);
  }

  console.log(productCondition);
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          description: "",
          sku: "",
          retailCost: "",
          images: [],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          const itemData = new FormData();
          itemData.append("sellerStore", storeId);
          itemData.append("type", type);
          itemData.append("product", product);

          itemData.append("size", JSON.stringify(allSelectedSizes));
          itemData.append("gender", gender);
          itemData.append("productCondition", productCondition);

          if (recommendedItems.length > 0) {
            itemData.append(
              "recommendedItems",
              JSON.stringify(recommendedItems)
            );
          }

          try {
            const response = await axiosInstance.post(
              PRODUCT_API + "createProduct",
              itemData
            );
            if (response.data) {
              setSubmitting(false);
              setShowCreateForm(false);
              setShowModal(false);
              triggerFetch();
            }
          } catch (error) {
            console.error(error);
            toast.error("Failed to create product");
            setSubmitting(false);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
          <VStack spacing={"3"} align="stretch">
            <SearchProduct
              type={type}
              onProductSelect={(option) =>
                handleProductSelect(option, setFieldValue)
              }
            />
            <ShowSelectedProduct id={product} />
            {product && (
              <>
                <SelectSizeAndGender
                  allSelectedSizes={allSelectedSizes}
                  gender={gender}
                  setGender={setGender}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  type={type}
                  setAllSelectedSizes={setAllSelectedSizes}
                  selectedSize={selectedSize}
                  size={size}
                  setSize={setSize}
                  setSelectedSize={setSelectedSize}
                  setProductCondition={setProductCondition}
                  productCondition={productCondition}
                />
                <SelectProduct
                  storeId={storeId}
                  setRecommendedItems={setRecommendedItems}
                />
              </>
            )}
            <FormSubmitButton
              disabled={disabled}
              text="Create Product"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Uploading..."
            />
          </VStack>
        )}
      </Formik>
      {showTips && (
        <Modal
          handleClose={handleClose}
          modalHeading={"Quick Guide to Sneaker Photo Uploads"}
        >
          <Tips
            setShowTips={setShowTips}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
    </>
  );
}

export default CreateProduct;
