import { Link } from 'react-router-dom';
import './NavCard.css';

function NavCard({children, cardName, navCardLink, hasText, heading}) {
  return (
    <div className='nav_card'>
      <Link to={navCardLink}>
        <div className='nav_card_container'>
            {children}
            {hasText && <h1>{heading}</h1>}
            <p className='nav_card_name'>{cardName}</p>
        </div>
      </Link>  
    </div>
  )
}

export default NavCard