import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_API } from "../../../../../../Utilities/APIs/APIs";

export default function useFetchSingleProduct(id) {
  const axiosInstance = useAxiosInstance();
  const [selectedProduct, setSelectedProduct] = useState(null);

  console.log(id, "id");
  useEffect(() => {
    async function fetchSingleProduct() {
      const response = await axiosInstance.get(
        PRODUCT_API + `getSingleProductAllItem/${id}`
      );
      console.log(response);
      const { productData } = response.data;
      setSelectedProduct(productData);
    }
    fetchSingleProduct();
  }, [id]);
  return selectedProduct;
}
