import { Image } from '@chakra-ui/react'
import React from 'react'

export default function SidebarImage() {
  return (
     <Image
    src="https://store.cheb.antopolis.xyz/assets/logo/cheb-logo.svg"
    alt="logo"
    width={100}
  />
  )
}
