import { useEffect, useState } from "react";
// import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_API } from "../../../../Utilities/APIs/APIs";
import { Image, HStack } from "@chakra-ui/react";

function ViewProduct({ targetID }) {
  console.log(targetID);
  const [productInfo, setproductInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  const [images, setImages] = useState([]);
  useEffect(() => {
    async function fetchAndsetProductInfo() {
      const { data } = await axiosInstance.get(
        PRODUCT_API + "getSingleProduct/" + targetID
      );
      console.log(data);
      setproductInfo(data);
    }
    fetchAndsetProductInfo();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetProductImages() {
      const { data } = await axiosInstance.get(
        PRODUCT_API + "productImages/" + targetID
      );
      setImages(data);
    }
    fetchAndSetProductImages();
  }, [axiosInstance, targetID]);

  // console.log("card image is", process.env.REACT_APP_SPACES_URL + productInfo?.cardImage)
  return (
    <div className="crud_view_content">
      {productInfo && (
        <>
          <h1>Card Image</h1>
          <HStack
            wrap={"wrap"}
            spacing={3}
            align={"center"}
            justifyContent={"center"}
          >
            <Image
              w="80px"
              h="80px"
              src={process.env.REACT_APP_SPACES_URL + productInfo?.cardImage}
              objectFit="contain"
              alt="Product Card Images"
            />
            {/* <Image imgLink={productInfo?.cardImage} imgAlt={"Card Image"} /> */}
          </HStack>
          <h1>Product Name</h1>
          <p>{productInfo?.name}</p>

          <h1>Sku</h1>
          <p>{productInfo.sku}</p>

          {/* <h1>Colorway</h1>
          <p>{productInfo.colorWay}</p> */}

          {/* <h1>Release Date</h1>
          <p>{new Date(productInfo.createdAt).toLocaleDateString()}</p> */}

          <h1>Retail Costs</h1>
          <p>{productInfo.retailCost}</p>

          {/* <h1>Condition</h1>
          <p>{productInfo.condition}</p>
 */}

          {/* <h1>Slug</h1>
          <p>{productInfo.slug}</p> */}

          <h1>Product Images</h1>
          <HStack flexWrap={"wrap"} spacing={3}>
            {images.map((image, index) => (
              <div key={image._id}>
                <Image
                  w="80px"
                  h="80px"
                  src={process.env.REACT_APP_SPACES_URL + image.image}
                  objectFit="contain"
                  alt="Product Images"
                />
              </div>
            ))}
          </HStack>
        </>
      )}
    </div>
  );
}

export default ViewProduct;
