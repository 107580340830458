import React from "react";
import Modal from "../../../../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import { SIZE_API } from "../../../../../../../Utilities/APIs/APIs";
import CreateSize from "./components/CreateSize";
import UpdateSize from "./components/updateSize/UpdateSize";
import ViewSize from "./components/viewSize/ViewSize";

export default function ProductSizeModal({
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowDeleteSection,
  targetID,
  seller,
  storeId,
  triggerFetch,
  setShowModal,
}) {
  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Size`) ||
            (showUpdateForm && `Update Size `) ||
            (showDeleteSection && `Delete Size`)
          }
        >
          {showCreateForm && (
            <CreateSize
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateSize
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={SIZE_API + "deleteSize/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Size`}
        >
          <ViewSize targetID={targetID} seller={seller} />
        </Modal>
      )}
    </div>
  );
}
