import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

export default function ProductColorTable({
  colors,
  setShowModal,
  setShowViewSection,
  setShowUpdateForm,
  setShowDeleteSection,
  setTargetID,
}) {
  return (
    <div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {colors?.map((color) => (
            <CRUDTableRow key={color._id}>
              <ShortTextCell text={color.name} />
              <td className="action_button_cell">
                <ViewButton
                  setShowModal={setShowModal}
                  setShowViewSection={setShowViewSection}
                  targetID={color._id}
                  setTargetID={setTargetID}
                />
                <EditButton
                  setShowModal={setShowModal}
                  setShowUpdateForm={setShowUpdateForm}
                  targetID={color._id}
                  setTargetID={setTargetID}
                />
                <DeleteButton
                  setShowModal={setShowModal}
                  setShowDeleteSection={setShowDeleteSection}
                  targetID={color._id}
                  setTargetID={setTargetID}
                />
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
