import { useState } from "react";

import ScreenHolder from "../../../../Partials/Layouts/ScreenHolder/ScreenHolder";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import EmailSentInfo from "./components/EmailSentInfo/EmailSentInfo";
import EmailSentInput from "./components/EmailSentInput/EmailSentInput";

import "../../Auth.css";

function RecoverPassOne() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const axiosInstance = useAxiosAuthInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    if (email.length > 0) {
      const forgotPass = await axiosInstance.post("forgotSellerPassword", {
        email,
      });
      if (forgotPass) {
        setEmailSent(true);
      }
    }
  }

  async function handleResend() {
    if (email.length > 0) {
      const forgotPass = await axiosInstance.post("forgotSellerPassword", {
        email,
      });
      if (forgotPass) {
        setEmailSent(true);
      }
    }
  }

  return (
    <ScreenHolder>
      {emailSent ? (
        <EmailSentInfo email={email} handleResend={handleResend} />
      ) : (
        <EmailSentInput
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
        />
      )}
    </ScreenHolder>
  );
}

export default RecoverPassOne;
