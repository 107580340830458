import React from "react";
import Modal from "../../../../../../Partials/Elements/Modal/Modal";
import CreateColor from "../../CreateColor";
import UpdateColor from "../../UpdateColor";
import DeleteItem from "../../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewColor from "../../ViewColor";
import { COLOUR_API } from "../../../../../../../Utilities/APIs/APIs";

export default function ProductColorModal({
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowModal,
  setShowDeleteSection,
  targetID,
  triggerFetch,
  seller,
  storeId,
}) {
  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Color`) ||
            (showUpdateForm && `Update Color `) ||
            (showDeleteSection && `Delete Color`)
          }
        >
          {showCreateForm && (
            <CreateColor
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateColor
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={COLOUR_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Color `}
        >
          <ViewColor targetID={targetID} seller={seller} />
        </Modal>
      )}
    </div>
  );
}
