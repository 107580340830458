import React from "react";
import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

export default function ProductSizeTable({
  sizes,
  setShowModal,
  setShowViewSection,
  setShowUpdateForm,
  setShowDeleteSection,
  setTargetID,
}) {
  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Name" />
        <CRUDth th="Actions" />
      </CRUDTableHeader>
      <tbody>
        {sizes?.map((size) => (
          <CRUDTableRow key={size._id}>
            <ShortTextCell text={size.name} />
            <td className="action_button_cell">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDown />}
                  size="md"
                  bg="#322A5D30"
                  // color='white'
                  _hover={{
                    bg: "#322A5D",
                    color: "white",
                  }}
                  _active={{
                    bg: "#322A5D",
                    color: "white",
                  }}
                >
                  Actions
                </MenuButton>
                <MenuList>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={size._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={size._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={size._id}
                    setTargetID={setTargetID}
                  />
                  {/* <CRUDButton
                    handleClick={() => navigate("/sizeAsset/" + size._id)}
                  >
                    <RiImage2Line />
                  </CRUDButton> */}
                </MenuList>
              </Menu>
            </td>
          </CRUDTableRow>
        ))}
      </tbody>
    </CRUDTable>
  );
}
