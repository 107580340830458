import { Button } from "@chakra-ui/react";
import { useContext } from "react";
import { HiLogout } from "react-icons/hi";
import { AppContext } from "../../../../../AppContext/AppContext";

export default function LogoutButton() {
  const { logout } = useContext(AppContext);

  return (
    <div>
      <Button
        w="100%"
        colorScheme="ghost"
        rightIcon={<HiLogout />}
        bg="#322A5D30"
        color={"#322A5D"}
        _hover={{
          bg: "#322A5D50",
        }}
        fontSize="small"
        justifyContent="flex-start"
        paddingY="18px"
        onClick={logout}
      >
        Log Out
      </Button>
    </div>
  );
}
