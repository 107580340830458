import React from "react";
import SelectGender from "../SelectGender/SelectGender";
import SelectSize from "../SelectSize/SelectSize";
import SelectPrice from "../SelectPrice/SelectPrice";
import SelectedSize from "../SelectedSize";
import SelectProductCondition from "../SelectProductCondition";

export default function SelectSizeAndGender({
  gender,
  setGender,
  setDisabled,
  type,
  allSelectedSizes,
  setAllSelectedSizes,
  selectedSize,
  size,
  setSize,
  setSelectedSize,
  setProductCondition,
  productCondition,
}) {
  console.log(allSelectedSizes);
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SelectGender setState={setGender} value={gender} />
        <SelectSize
          type={type}
          gender={gender}
          size={size}
          setSize={setSize}
          setSelectedSize={setSelectedSize}
        />
        <SelectProductCondition
          size={size}
          type={type}
          productCondition={productCondition}
          setProductCondition={setProductCondition}
        />
        <SelectPrice
          size={size}
          gender={gender}
          selectedSize={selectedSize}
          setSize={setSize}
          allSelectedSizes={allSelectedSizes}
          setDisabled={setDisabled}
          setAllSelectedSizes={setAllSelectedSizes}
          setProductCondition={setProductCondition}
          productCondition={productCondition.value}
        />
      </div>
      <SelectedSize
        allSelectedSizes={allSelectedSizes}
        setAllSelectedSizes={setAllSelectedSizes}
      />
    </div>
  );
}
