import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import { Chakra_Styles } from "../../../MyStore/MyStore";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";

export default function OrderTable({
  orders,
  navigate,
  setShowModal,
  setShowViewSection,
  setTargetID,
  setShowProcessingOrder,
  setShowCancelOrder,
  orderStatus,
  buttonText,
}) {
  return (
    <div>
      <CRUDTable>
        <tbody>
          {orders?.map((order) => (
            <CRUDTableRow key={order?._id}>
              <ShortTextCell text={order?.customer && order?.customer.name} />
              <ShortTextCell
                text={new Date(order?.createdAt).toLocaleDateString()}
              />
              <ShortTextCell text={Math.ceil(order?.total)} />

              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={order._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>
                        Order Details
                      </Text>
                    </ViewButton>

                    <CRUDButton
                      dataTip="Product"
                      dataFor="Product Item"
                      handleClick={() =>
                        navigate("/mystores/orderItems/" + order._id)
                      }
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>Order Items</Text>
                    </CRUDButton>

                    {(orderStatus === "processing" ||
                      orderStatus === "toBeDelivered" ||
                      orderStatus === "shipped") && (
                      <CRUDButton
                        dataTip="Status"
                        dataFor="Order Status"
                        handleClick={() => {
                          setShowModal(true);
                          setShowProcessingOrder(true);
                          setTargetID(order._id);
                        }}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          {buttonText()}
                        </Text>
                      </CRUDButton>
                    )}

                    {orderStatus !== "completed" &&
                      orderStatus !== "canceled" && (
                        <CRUDButton
                          dataTip="Status"
                          dataFor="Order Status"
                          handleClick={() => {
                            setShowModal(true);
                            setShowCancelOrder(true);
                            setTargetID(order._id);
                          }}
                        >
                          <Text {...Chakra_Styles.actionMenuItem}>Cancel</Text>
                        </CRUDButton>
                      )}
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
