
// import axios from 'axios'
// import { ORDERS_API } from '../../../../Utilities/APIs'

function OrderDelivered({
    setShowModal, setShowProcessingOrder, targetID, handleStatusChange
}) {
   
    return (
        <div className='modal_delete_content'>
            <p style={{textAlign: 'center'}}>
                
                Are you sure you want to deliver this order?

            </p>

            <div className="modal_group_buttons">
                <button
                    className="delete"
                  onClick={() => handleStatusChange(targetID)}
                >Accept</button>

                <button className="cancel" onClick={() => {
                    setShowModal(false);
                    setShowProcessingOrder(false)
                }}>Cancel</button>
            </div>

        </div>
    )
}

export default OrderDelivered