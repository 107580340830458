
export default function SelectCondition({ productCondition }) {
  return (
    <div style={{ width: "100%" }}>
      <input
        type="text"
        className="price_input"
        value={productCondition}
        readOnly
        style={{ cursor: "not-allowed" }}
      />
    </div>
  );
}
