import React from "react";

export default function OrderItemsHeading({ orderItems }) {
  return (
    <div>
      <div className="screen_header">
        <h1 className="screen_heading">
          Order Items ({orderItems?.length} in total)
        </h1>
      </div>
    </div>
  );
}
