import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { STORE_API } from "../../../../Utilities/APIs/APIs";

export default function useFetchSingleStore({ id }) {
  const axiosInstance = useAxiosInstance();
  const [store, setStore] = useState({});

  useEffect(() => {
    async function fetchAndSetStores() {
      const { data } = await axiosInstance.get(
        STORE_API + `getSellerSinglestore/${id}`
      );
      setStore(data);
    }
    fetchAndSetStores();
  }, [axiosInstance, id]);

  return store;
}
