import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../Utilities/APIs/APIs";
import { RiRepeatLine } from "react-icons/ri";

import "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import { AppContext } from "../../../AppContext/AppContext";
import OrderHeading from "./components/OrderHeading";
import OrderTable from "./components/OrderTable/OrderTable";
import OrderModal from "./components/OrderModal/OrderModal";

function Order() {
  const { storeId, orderStatus } = useParams();
  const { seller, triggerFetch, toggleFetch } = useContext(AppContext);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showProcessingOrder, setShowProcessingOrder] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAndsetOrders() {
      const { data } = await axiosInstance.get(
        `${ORDERS_API}?store=${storeId}&orderStatus=${orderStatus}`
      );
      setOrders(data);
    }
    fetchAndsetOrders();
  }, [axiosInstance, storeId, orderStatus, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowProcessingOrder(false);
    setShowCancelOrder(false);
  }

  function buttonText() {
    switch (orderStatus) {
      case "processing":
        return "Confirm Order";

      case "toBeDelivered":
        return "Ship Order";

      case "shipped":
        return "Complete This";

      default:
        return <RiRepeatLine />;
    }
  }

  async function handleStatusChange(id) {
    switch (orderStatus) {
      case "processing":
        await axiosInstance.patch(
          ORDERS_API + "/toBeDeliveredFromProcessing/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      case "toBeDelivered":
        await axiosInstance.patch(
          ORDERS_API + "/shippedFromToBeDelivered/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      case "shipped":
        await axiosInstance.patch(
          ORDERS_API + "/completedFromShipped/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      default:
        return console.log("Status Not Changed");
    }
  }

  return (
    <>
      <div className="crud_holder">
        <OrderHeading orders={orders} />
        <OrderTable
          orders={orders}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
          setShowProcessingOrder={setShowProcessingOrder}
          setShowCancelOrder={setShowCancelOrder}
          buttonText={buttonText}
          navigate={navigate}
          orderStatus={orderStatus}
        />
      </div>

      <OrderModal
        handleClose={handleClose}
        showModal={showModal}
        showViewSection={showViewSection}
        showProcessingOrder={showProcessingOrder}
        showCancelOrder={showCancelOrder}
        setShowModal={setShowModal}
        setShowProcessingOrder={setShowProcessingOrder}
        setShowCancelOrder={setShowCancelOrder}
        targetID={targetID}
        orderStatus={orderStatus}
        seller={seller}
        triggerFetch={triggerFetch}
        handleStatusChange={handleStatusChange}
      />
    </>
  );
}

export default Order;
