import ReactTooltip from 'react-tooltip'
import './CRUDButton.css'
import { MenuItem } from '@chakra-ui/react'

function CRUDButton ({ children, handleClick, deleteButton, dataTip, dataFor }) {
  const _component = () => {
    try {
      return (
        <MenuItem
          data-tip={dataTip}
          data-for={dataFor}
          className={deleteButton ? 'delete_btn' : ''}
          onClick={handleClick}
        >
          {children}
        </MenuItem>
      )
    } catch (ex) {
      return (
        <button
          data-tip={dataTip}
          data-for={dataFor}
          className={deleteButton ? 'delete_btn' : ''}
          onClick={handleClick}
        >
          {children}
        </button>
      )
    }
  }

  return _component()

  // return (
  //   <>

  //   </>
  // )
}

export default CRUDButton
