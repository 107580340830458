import { useParams } from "react-router-dom";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../Utilities/APIs/APIs";
import {
  RiCentosLine,
  RiCheckboxMultipleLine,
  RiDeleteBin2Line,
  RiGift2Line,
  RiTruckLine,
} from "react-icons/ri";

function OrderNavlink() {
  const { storeId } = useParams();
  const axiosInstance = useAxiosInstance();
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    async function fetchAndSetItems() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "/getAllOrders/" + storeId
      );
      setOrders(data);
    }
    fetchAndSetItems();
  }, [axiosInstance, storeId]);

  return (
    <NavCardList numOfCards="five">
      <NavCard
        cardName={`Processing(${
          orders?.filter((i) => i.orderStatus === "processing")?.length
        })`}
        navCardLink={`/mystores/order/${storeId}/processing`}
      >
        <RiCentosLine />
      </NavCard>

      <NavCard
        cardName={`To Be Delivered(${
          orders?.filter((i) => i.orderStatus === "toBeDelivered")?.length
        })`}
        navCardLink={`/mystores/order/${storeId}/toBeDelivered`}
      >
        <RiGift2Line />
      </NavCard>

      <NavCard
        cardName={`Shipped(${
          orders?.filter((i) => i.orderStatus === "shipped")?.length
        })`}
        navCardLink={`/mystores/order/${storeId}/shipped`}
      >
        <RiTruckLine />
      </NavCard>

      <NavCard
        cardName={`Completed(${
          orders?.filter((i) => i.orderStatus === "completed")?.length
        })`}
        navCardLink={`/mystores/order/${storeId}/completed`}
      >
        <RiCheckboxMultipleLine />
      </NavCard>

      <NavCard
        cardName={`Canceled(${
          orders?.filter((i) => i.orderStatus === "canceled")?.length
        })`}
        navCardLink={`/mystores/order/${storeId}/canceled`}
      >
        <RiDeleteBin2Line />
      </NavCard>
    </NavCardList>
  );
}

export default OrderNavlink;
