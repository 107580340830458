import InputBoilerplate from "../InputBoilerplate";

function ShortTextInput({
  name,
  label,
  value,
  placeholder,
  setState,
  setError,
  disabled,
}) {
  return (
    <InputBoilerplate
      name={name}
      label={label}
      type={"text"}
      value={value}
      placeholder={placeholder}
      setState={setState}
      setError={setError}
      disabled={disabled}
    />
  );
}

export default ShortTextInput;
