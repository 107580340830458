import { useState } from "react";
import useAxiosInstance from "../../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { SIZE_API } from "../../../../../../../../../Utilities/APIs/APIs";
import { Form } from "formik";
import ShortTextInput from "../../../../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import { NumberInput } from "@chakra-ui/react";
import FormSubmitButton from "../../../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useFetchSize from "../Hooks/useFetchSize";

function UpdateSize({
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
}) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState(null);
  useFetchSize({ targetID, setName, setPrecedence });

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { name, precedence };

    const response = await axiosInstance.patch(
      SIZE_API + "updateSize/" + targetID,
      itemData
    );
    console.log(response.data);

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Size Name`}
        value={name}
        placeholder={`Enter Size Name`}
        setState={setName}
      />

      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />

      <FormSubmitButton text="Create Store" />
    </Form>
  );
}

export default UpdateSize;
