import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function LoginBottom() {
  const navigate = useNavigate();

  function goToRegister() {
    navigate("/register");
  }

  return (
    <>
      <HStack pt="5" justify="space-between">
        <Text fontSize="small">Forgot Password</Text>
        <Button
          variant="ghost"
          fontSize="small"
          bg="#322A5D10"
          color={"#322A5D"}
          _hover={{
            bg: "#322A5D50",
          }}
          onClick={() => navigate("/recover-pass-one")}
        >
          Reset
        </Button>
      </HStack>
      <HStack pt="10" justify="space-between">
        <Text fontSize="small">Not yet seller?</Text>
        <Button
          variant="ghost"
          fontSize="small"
          bg="#322A5D10"
          color={"#322A5D"}
          _hover={{
            bg: "#322A5D50",
          }}
          onClick={goToRegister}
        >
          Sign Up
        </Button>
      </HStack>
    </>
  );
}
