import React from "react";
import Select from "react-select";

export default function SelectGender({ setState, value, hasLabel }) {
  const options = [
    { value: "men", label: "Men" },
    { value: "women", label: "Women" },
  ];

  return (
    <div style={{ width: "100%" }}>
      {hasLabel && (
        <p className="input_field_label" style={{ marginBottom: "10px" }}>
          Select Gender
        </p>
      )}

      <Select
        placeholder={"Select Gender"}
        options={options}
        value={options.find((option) => option.value === value || "")}
        onChange={(selectedOption) => setState(selectedOption.value)}
      />
    </div>
  );
}
