import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { SIZE_API } from "../../../../../../Utilities/APIs/APIs";

export default function useFetchSize({ toggleFetch, storeId }) {
  const axiosInstance = useAxiosInstance();
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(SIZE_API + "getAllSize");
      setSizes(data);
    }
    fetchAndSetSizes();
  }, [axiosInstance, toggleFetch, storeId]);

  return sizes;
}
