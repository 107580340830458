import useFetchSize from "../Hooks/useFetchSize";

function ViewSize({ targetID }) {
  const sizeInfo = useFetchSize({ targetID });

  return (
    <div className="crud_view_content">
      <>
        <h1>Size Name</h1>
        <p>{sizeInfo?.name}</p>

        <h1>Precedence</h1>
        <p>{sizeInfo?.precedence}</p>
      </>
    </div>
  );
}

export default ViewSize;
