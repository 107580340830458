import { IMAGE_URL } from '../../../../Utilities/APIs/APIs'
import { Image as ChakraImage } from '@chakra-ui/react'

function Image ({ imgLink, imgAlt, style }) {
  // const _style = style || {}
  return (
    imgLink && (
      <ChakraImage
        src={IMAGE_URL + imgLink}
        alt={imgAlt}
        style={{
          ...style
        }}
      />
    )
  )
}

export default Image
