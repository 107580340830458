import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";

function ViewPaymentCardInfo({ targetID }) {
  const [storeInfo, setStoreInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  //   bankName,accountHolderName,accountNumber,routingNumber,mobile,email,bankAddress
  useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get("cardInfo/" + targetID);
      setStoreInfo(data);
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance]);
  return (
    <div className="crud_view_content">
      {storeInfo && (
        <>
          <h1>Bank Name</h1>
          <p>{storeInfo?.bankName}</p>

          <h1>Bank Address</h1>
          <p>{storeInfo?.bankAddress}</p>

          <h1>Account Holder Name</h1>
          <p>{storeInfo.accountHolderName}</p>

          <h1>Account Number</h1>
          <p>{storeInfo.accountNumber}</p>

          <h1>Routing Number</h1>
          <p>{storeInfo.routingNumber}</p>

          <h1>Email</h1>
          <p>{storeInfo.email}</p>
        </>
      )}
    </div>
  );
}

export default ViewPaymentCardInfo;
