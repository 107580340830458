import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { AppContext } from "../../../../AppContext/AppContext";

export default function useAllPaymentInfo() {
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(AppContext);

  const [totalOrderValue, setTotalOrderValue] = useState("");
  const [totalPaidPayment, setTotalPaidPayment] = useState("");
  const [approvePaymentCount, setApprovePaymentCount] = useState("");
  const [allPaymentsToSeller, setAllPaymentToSeller] = useState([]);

  useEffect(() => {
    async function fetchAndsetAllPaymentInfo() {
      try {
        const { data } = await axiosInstance.get("dashboardPaymentInfo");
        setTotalOrderValue(data.totalOrderValue);
        setTotalPaidPayment(data.totalPaidValue);
        setApprovePaymentCount(data.paymentApprovalCount);
        setAllPaymentToSeller(data.allPaymentsToSeller);
      } catch (error) {
        console.log("error is", error);
      }
    }

    fetchAndsetAllPaymentInfo();
  }, [toggleFetch]);

  return {
    totalOrderValue,
    totalPaidPayment,
    approvePaymentCount,
    allPaymentsToSeller,
  };
}
