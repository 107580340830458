import React from "react";
import CRUDTable from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import EditButton from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import { Chakra_Styles } from "../../../../../MyStore";
import DeleteButton from "../../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

export default function ProductColorTable({
  productColor,
  productName,
  setShowModal,
  setShowUpdateForm,
  setShowDeleteSection,
  setTargetID,
}) {
  return (
    <div>
      <CRUDTable>
        <tbody>
          {productColor?.map((color) => (
            <CRUDTableRow key={color?._id}>
              <ShortTextCell text={productName} />
              <ShortTextCell
                text={
                  `${color.color?.name}` +
                  `${color.colorWay ? ` - Colorway: ${color.colorWay}` : ""}`
                }
              />
              {/* <ShortTextCell text={color.color?.name} /> */}
              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={color._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>Edit</Text>
                    </EditButton>
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={color._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>Delete</Text>
                    </DeleteButton>
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
