import { RiCloseLine } from "react-icons/ri";

import "./SelectedSize.css";

export default function SelectedSize({
  setAllSelectedSizes,
  allSelectedSizes,
}) {
  function handleRemoveSize({ id }) {
    const newSelectedSizes = allSelectedSizes.filter(
      (size) => size.value !== id
    );
    setAllSelectedSizes(newSelectedSizes);
  }

  return (
    <div className="selected_size_area">
      {allSelectedSizes.map((size) => (
        <div key={size.value} className="selected_size">
          <p>{size.label}</p>
          |
          <p> &#36;{size.price}</p> 
          |
          <p> {size.gender === "women" ? "W" : "M"}</p> 
          |
          <p>{size.stock}</p>
          |
          <p>{size?.productCondition}</p>
          <button onClick={() => handleRemoveSize({ id: size.value })}>
            <RiCloseLine size={16} />
          </button>
        </div>
      ))}
    </div>
  );
}
