import "./Modal.css";
import ModalHeader from "./ModalHeader/ModalHeader";

function Modal({ modalHeading, children, extraClass, handleClose }) {
  return (
    <div className={`modal ${extraClass}`}>
      <div className="modal_backdrop" onClick={() => handleClose(false)}></div>
      <div
        className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}
      >
        <div className="modal_content">
          <ModalHeader modalHeading={modalHeading} handleClose={handleClose} />
          <div className="modal_body">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
