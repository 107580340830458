import { RiDashboardLine } from "react-icons/ri";
import { MdOutlinePendingActions } from "react-icons/md";
import { RiMoneyEuroBoxLine } from "react-icons/ri";

import SidebarItem from "./SidebarItem/SidebarItem";
import LogoutButton from "./LogoutButton";

import "./SidebarItems.css";
import { VStack } from "@chakra-ui/react";

const Menu_Item = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine />,
  },
  {
    title: "My Stores",
    icon: <MdOutlinePendingActions />,
  },
  {
    title: "Payment Card Info",
    icon: <RiMoneyEuroBoxLine />,
  },
];

function SidebarItems() {
  return (
    <VStack h="100%" w="100%" px="1rem" justify="space-between" pb="1rem">
      <VStack w="100%" h="100%">
        {Menu_Item.map((menu_item) => (
          <SidebarItem key={`menu_item_${menu_item.title}`} {...menu_item} />
        ))}
      </VStack>
      <LogoutButton />
    </VStack>
  );
}

export default SidebarItems;
