import React from "react";
import Modal from "../../../../../../../Partials/Elements/Modal/Modal";
import CreateProductSize from "../CreateProductSize";
import UpdateProductSize from "../UpdateProductSize";
import DeleteItem from "../../../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import { PRODUCT_SIZE_API } from "../../../../../../../../Utilities/APIs/APIs";
import ViewProductSize from "../ViewProductSize";

export default function ProductSizeModal({
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowModal,
  setShowDeleteSection,
  targetID,
  triggerFetch,
  seller,
  productId,
  productName,
  productSizes,
}) {
  return (
    <div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Size`) ||
            (showUpdateForm && `Update Size `) ||
            (showDeleteSection && `Delete Size`)
          }
          extraClass={"overflow-modal"}
        >
          {showCreateForm && (
            <CreateProductSize
              seller={seller}
              productId={productId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              preSelectedValues={productSizes}
            />
          )}
          {showUpdateForm && (
            <UpdateProductSize
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={PRODUCT_SIZE_API + "deleteProductSize/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        // Overflow set to `visible` to show all the options of the new dropdown
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product Size`}
          extraClass={"overflow-modal"}
        >
          <ViewProductSize
            productName={productName}
            quantity={productSizes}
            targetID={targetID}
          />
        </Modal>
      )}
    </div>
  );
}
