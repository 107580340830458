import React from "react";
import ScreenHolder from "../../../Partials/Layouts/ScreenHolder/ScreenHolder";
import { VStack } from "@chakra-ui/react";

export default function LoginWrapper({ children }) {
  return (
    <ScreenHolder>
      <VStack
        bg="white"
        p="10"
        borderRadius="md"
        boxShadow={"0px 0px 30px 4px rgba(0,0,0,0.05)"}
      >
        <div className="form_wrapper">
            {children}
        </div>
      </VStack>
    </ScreenHolder>
  );
}
