import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import { PRODUCT_COLOUR_API } from "../../../../../../../Utilities/APIs/APIs";
import { Input, VStack, Text, Box } from "@chakra-ui/react";
import { toast } from "react-toastify";

function CreateProductColor({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  productId,
}) {
  const [color, setColor] = useState("");
  // const [colorWay, setColorWay] = useState('')
  const [colors, setColors] = useState(null);
  const [isAPIBusy, setIsAPIBusy] = useState();

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetColor() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLOUR_API + "getAllColor/" + productId
      );
      setColors(data);
    }
    fetchAndsetColor();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    // if (!color) {
    //   toast.error("Please add a color")
    //   return;
    // }
    // CreateableSelect returns us an array of values. Ex: [32, 12, Male: 25, Female: 32...]
    if (Array.isArray(colors)) {
      handleCreateableSelect();
    } else {
      handleRegularSelect();
    }
  }

  async function handleRegularSelect() {
    const itemData = new FormData();

    itemData.append("product", productId);
    itemData.append("color", color);
    // itemData.append('colorWay', colorWay)

    const response = await axiosInstance.post(PRODUCT_COLOUR_API, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
      setShowModal(false);
    }
  }

  async function handleCreateableSelect() {
    const _promises = color.map(async (color_single) => {
      const itemData = new FormData();
      console.log("Single size:", color_single);
      itemData.append("product", productId);
      itemData.append("color", color_single);
      // itemData.append('colorWay', colorWay)
      return await axiosInstance.post(PRODUCT_COLOUR_API, itemData);
    });

    try {
      await Promise.all(_promises);

      triggerFetch();
      setShowCreateForm(false);
      setShowModal(false);
    } catch (ex) {
      console.log("Something went wrong with CreateableSelect");
    }
  }

  return (
    <div>
      {!colors || colors.length === 0 ? (
        <Box>
          <Text fontSize={"unset"} textColor={"red"}>
            Alredy Added All Colors!
          </Text>
        </Box>
      ) : (
        <Form onSubmit={handleSubmit} hasImage>
          <VStack align={"stretch"} mb={"1.5rem"}>
            <SelectInput
              label={"Color"}
              value={color}
              setState={setColor}
              useCreateableSelect
              options={colors}
            ></SelectInput>
            {/* <Input
              size='larger'
              placeholder='Color Way...'
              bg={'white'}
              px='4'
              py='3'
              borderRadius={'sm'}
              value={colorWay}
              onChange={e => setColorWay(e.target.value)}
            /> */}
          </VStack>
          <FormSubmitButton isLoading={isAPIBusy} text="Create Product Color" />
        </Form>
      )}
    </div>
  );
}

export default CreateProductColor;
