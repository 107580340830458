import React from "react";
import useFetchSingleProduct from "../Hooks/useFetchSingleProduct";

export default function ShowSelectedProduct({ id }) {
  const selectedProduct = useFetchSingleProduct(id);

  console.log(selectedProduct, "selectedProduct");

  return (
    <div>
      {id && selectedProduct && (
        <img
          src={process.env.REACT_APP_SPACES_URL + selectedProduct.cardImage}
          alt=""
          style={{ width: "100px", height: "100px" }}
        />
      )}
    </div>
  );
}
