import { FiTrash2 } from 'react-icons/fi';

import CRUDButton from '../CRUDButton';

import './DeleteButton.css';
import { Children } from 'react';

function DeleteButton({children, setShowModal, setShowDeleteSection, targetID, setTargetID }) {
  return (
    <CRUDButton
      dataTip='Delete'
      dataFor='Delete Item'
      handleClick={() => {
        setShowModal(true)
        setShowDeleteSection(true)
        setTargetID(targetID)
      }}
      deleteButton
    >
      {children}
    </CRUDButton>
  )
}

export default DeleteButton