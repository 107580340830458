import React, { useState } from "react";
import { RiAddFill } from "react-icons/ri";
import { toast } from "react-toastify";

export default function SelectPrice({
  size,
  allSelectedSizes,
  selectedSize,
  gender,
  setAllSelectedSizes,
  setSize,
  setDisabled,
  productCondition,
  setProductCondition,
}) {
  const [inputValue, setInputValue] = useState("");
  const [stock, setStock] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  function handleSizeChange() {
    if (!inputValue) {
      return toast.error("Please enter a price");
    }

    const newSize = {
      ...size,
      price: inputValue,
      gender,
      stock,
      productCondition,
    }; // Set price in newSize

    const existingSize = allSelectedSizes.find(
      (size) => size.value === selectedSize
    );
    if (existingSize) {
      return toast.error("Size already exists");
    }

    setAllSelectedSizes([...allSelectedSizes, newSize]);
    setSize(null);
    setInputValue("");
    setDisabled(false);
    setProductCondition("");
    setStock("");
  }

  const handleStockChange = (e) => {
    setStock(e.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      {size && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <input
            type="number"
            placeholder=" Price"
            value={inputValue} // Bind the input value to the state
            onChange={handleChange}
            name="price"
            style={{
              width: "100%",
              minHeight: "4rem",
              borderRadius: "5px",
              border: "1px solid #ccc",
              padding: "0.5rem",
            }}
          />
          <input
            type="number"
            placeholder=" Stock"
            value={stock} // Bind the input value to the state
            onChange={handleStockChange}
            name="price"
            style={{
              width: "100%",
              minHeight: "4rem",
              borderRadius: "5px",
              border: "1px solid #ccc",
              padding: "0.5rem",
            }}
          />

          <button
            onClick={() => {
              handleSizeChange();
            }}
            className={`add_size_button ${
              inputValue === "" || stock === "" ? "disabled" : ""
            }`}
            disabled={inputValue === ""}
          >
            <RiAddFill size={18} />
          </button>
        </div>
      )}
    </div>
  );
}
