import Select from "react-select";

export default function SelectTimeZone({ value, setState }) {
  const options = [
    { value: "America/New_York", label: "America/New_York" },
    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "America/Adak", label: "America/Adak" },
    { value: "Pacific/Honolulu", label: "America/Honolulu" },
  ];

  return (
    <div>
      <Select
        options={options}
        value={value}
        onChange={(e) => setState(e)}
        placeholder={"Select Timezone"}
      />
    </div>
  );
}
