import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../../Utilities/Hooks/useAxiosInstance'
import Form from '../../../../../../Partials/Layouts/Forms/Form'
import FormSubmitButton from '../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import SelectInput from '../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput'
import { PRODUCT_SIZE_API } from '../../../../../../../Utilities/APIs/APIs'
import { Box, Text } from '@chakra-ui/react'
import { toast } from 'react-toastify'

function CreateProductSize({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  productId,
  preSelectedValues
}) {
  const [size, setSize] = useState('')
  const [sizes, setSizes] = useState(null)
  const [isAPIBusy, setIsAPIBusy] = useState();

  const axiosInstance = useAxiosInstance()

  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(PRODUCT_SIZE_API + 'getAllSize/' + productId)
      setSizes(data)
      // console.log('New sizes:', _reconstructSizes(data))
    }
    fetchAndSetSizes()
  }, [axiosInstance])

  // function _reconstructSizes (data) {
  //   console.log('Pre-values:', preSelectedValues)
  //   console.log('Sizes are:', data)
  //   if (!preSelectedValues) return sizes
  //   const _options = []
  //   preSelectedValues?.map(single => {
  //     if (!data.some(x => x._id === single.size._id)) {
  //       _options.push(single.size)
  //     }
  //   })

  //   return _options
  // }

  async function handleSubmit(e) {
    e.preventDefault()

    // CreateableSelect returns us an array of values. Ex: [32, 12, Male: 25, Female: 32...]
    if (Array.isArray(size)) {
      handleCreateableSelect()
    } else {
      handleRegularSelect()
    }
  }

  async function handleRegularSelect() {
   
    setIsAPIBusy(true);
    const itemData = new FormData()

    itemData.append('product', productId)
    itemData.append('size', size)

    const response = await axiosInstance.post(
      PRODUCT_SIZE_API + 'createProductSize',
      itemData
    )
    if (response) {
      triggerFetch()
      setShowCreateForm(false)
      setShowModal(false)
    }
    setIsAPIBusy(false);
  }
  async function handleCreateableSelect() {

    setIsAPIBusy(true);
    const _promises = size.map(async size_single => {
      const itemData = new FormData()
      itemData.append('product', productId)
      itemData.append('size', size_single)
      return await axiosInstance.post(
        PRODUCT_SIZE_API + 'createProductSize',
        itemData
      )
    })

    try {
      await Promise.all(_promises)
      triggerFetch()
      setShowCreateForm(false)
      setShowModal(false)
      setIsAPIBusy(false);
    } catch (ex) {
      console.log('Something went wrong with CreateableSelect')
    }
  }

  return (

    <div>

      {
        !sizes || sizes.length === 0 ? (
          <Box>
            <Text fontSize={"unset"} textColor={"red"}>Alredy Added All Sizes!</Text>
          </Box>
        ) : <Form onSubmit={handleSubmit} hasImage>
          <SelectInput
            label={'Size'}
            value={size}
            setState={setSize}
            useCreateableSelect
            options={sizes}
          >

          </SelectInput>
          <Box h='5' />
          <FormSubmitButton isLoading={isAPIBusy} text='Create Size' />
        </Form>
      }
    </div>

  )
}

export default CreateProductSize
