import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../../../AppContext/AppContext";
import ProductHeading from "./components/ProductHeading";
import ProductTable from "./components/ProuductTable/ProductTable";
import ProductModal from "./components/ProductModal/ProduuctModal";
import useFetchProducts from "./Hooks/useFetchProducts";

import "./Product.css";
import useFetchSingleStore from "../Hooks/useFetchSingleStore";

function Products() {
  const { storeId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [archive, setArchive] = useState(null);
  const [type, setType] = useState("");
  const { seller } = useContext(AppContext);
  const navigate = useNavigate();
  const [sellerStoreProductId, setSellerStoreProductId] = useState('')

  const products = useFetchProducts({ storeId, toggleFetch, type });

  const store = useFetchSingleStore({ id: storeId });



  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <ProductHeading
          store={store}
          type={type}
          setType={setType}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
          products={products}
        />
        <ProductTable
          type={type}
          products={products}
          setShowModal={setShowModal}
          setShowViewSection={setShowViewSection}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setTargetID={setTargetID}
          setArchive={setArchive}
          navigate={navigate}
          setSellerStoreProductId={setSellerStoreProductId}
        />
      </div>
      <ProductModal
        type={type}
        showModal={showModal}
        handleClose={handleClose}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
        targetID={targetID}
        triggerFetch={triggerFetch}
        seller={seller}
        archive={archive}
        storeId={storeId}
        toggleFetch={toggleFetch}
        sellerStoreProductId={sellerStoreProductId}
      />
    </>
  );
}

export default Products;
