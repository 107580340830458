import { useState } from "react";
// import UploadThumb from '../../../../../../Assets/img_upload.svg'

import "./SingleImageInput.css";
import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";

function SingleImageInput({
  fieldId,
  state,
  setState,
  allowCreateImage,
  allowUpdateImage,
  children,
  customStyle,
  disabled,
}) {
  //for image preview
  const [imagePreview, setImagePreview] = useState({ preview: "" });
  const handleChange = (e) => {
    setState(e.target.files[0]);
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };

  function triggerImageModal() {
    const input = document.getElementById(`fileInput${fieldId}`);
    input.click();
  }

  return (
    <VStack id="test_id" w="full" align="flex-start">
      <VStack
        pos="relative"
        w="80px"
        h="80px"
        justify="center"
        cursor="pointer"
        onClick={triggerImageModal}
        style={customStyle}
      >
        {allowUpdateImage && !imagePreview.preview && !state && (
          <>
            <FiUpload />
            <Text fontSize="smaller" fontWeight="600">
              {children || "Upload"}
            </Text>
          </>
        )}

        {allowCreateImage && !imagePreview.preview && (
          <>
            <FiUpload />
            <Text fontSize="smaller" fontWeight="600">
              {children || "Upload"}
            </Text>
          </>
        )}

        {allowUpdateImage && state && (
          <Image
            w="80px"
            h="80px"
            src={
              imagePreview.preview
                ? imagePreview.preview
                : process.env.REACT_APP_SPACES_URL + state
            }
            objectFit="contain"
          />
        )}
        {allowCreateImage && imagePreview.preview && (
          <Image
            objectFit="contain"
            w="80px"
            h="80px"
            src={imagePreview.preview}
          />
        )}
        <Box
          bg="#322A5D20"
          borderRadius="md"
          justify="center"
          transition="all 300ms"
          _hover={{
            bg: "#322A5D50",
          }}
          pos="absolute"
          h="100%"
          w="100%"
        />
      </VStack>

      <input
        id={`fileInput${fieldId}`}
        type="file"
        className="file_upload_input"
        onChange={handleChange}
        disabled={disabled}
      />
    </VStack>
  );
}

export default SingleImageInput;
