import Image from '../../../../../../../Elements/Image/Image'
import './ImageCell.css'

const DEFAULT_WIDTH = '40px'
const DEFAULT_HEIGHT = '40px'

function ImageCell ({ imgSrc, imgAlt, style, cellStyle }) {
  return (
    <td style={{ ...cellStyle, width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }}>
      <Image
        imgLink={imgSrc}
        imgAlt={imgAlt}
        style={{ ...style, width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }}
      />
    </td>
  )
}

export default ImageCell
