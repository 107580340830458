import "./SelectInput.css";
import Select from "react-select";

export const ReactSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    background: "white",
    borderWidth: "0",
    textAlign: "left",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: "black !important",
    placeholder: "Type here...",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "white !important",
    backgroundColor: "transparent",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    background: "white",
    zIndex: 99,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    ":hover": {
      backgroundColor: "#322A5D30",
    },
    backgroundColor: "white",
  }),
};

function SelectInput({
  value,
  setState,
  children,
  label,
  useCreateableSelect,
  options,
}) {
  console.log("Option is", options);
  function handleCreateableSelect(options) {
    // CreateableSelect emits a value like this: {value: '', label: ''}
    // Tho, note that, the `isMulti` is set to true in the compoenent, so what you'll recieve here is an array that looks like:
    // [ { value: '', label: ''} ]
    // To maintain the current state of the API code, we will pick only the `value` property of each object, in the array
    const _values = options?.map(({ value, label }) => value);
    setState(_values);
  }

  return !useCreateableSelect ? (
    <div className="input_group">
      <label className="input_field_label">{label}</label>
      <select
        className="input_field"
        value={value}
        onChange={(e) => setState(e.target.value)}
      >
        {children}
      </select>
    </div>
  ) : (
    <Select
      styles={ReactSelectStyles}
      placeholder={label}
      // onChange={val => updateData(rowIndex, 'source', val)}
      closeMenuOnSelect={false}
      options={options?.map(({ _id, name }) => ({ value: _id, label: name }))}
      isClearable
      isMulti
      onChange={handleCreateableSelect}
      // onCreateOption={val =>
      //   setCustomSources(prev => [
      //     ...prev,
      //     {
      //       label: val,
      //       value: val
      //     }
      //   ])
      // }
    />
  );
}

export default SelectInput;
