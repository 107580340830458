import { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../../AppContext/AppContext";
import ProductColorHeading from "./components/ProductColorHeading";
import ProductColorTable from "./components/ProductColorTable/ProductColortable";
import ProductColorModal from "./components/ProductColorModal/ProductColorModal";
import useFetchColor from "./Hooks/useFetchColor";

function ProductColor() {
  const { storeId } = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const { seller } = useContext(AppContext);

  const colors = useFetchColor({ toggleFetch, storeId });
  console.log(colors);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <ProductColorHeading
          colors={colors}
          search={search}
          setSearch={setSearch}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
        <ProductColorTable
          colors={colors}
          setShowModal={setShowModal}
          setShowViewSection={setShowViewSection}
          setShowUpdateForm={setShowUpdateForm}
          setShowDeleteSection={setShowDeleteSection}
          setTargetID={setTargetID}
        />
      </div>

      <ProductColorModal
        showModal={showModal}
        handleClose={handleClose}
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showDeleteSection={showDeleteSection}
        showViewSection={showViewSection}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
        targetID={targetID}
        triggerFetch={triggerFetch}
        seller={seller}
        store={storeId}
      />
    </>
  );
}

export default ProductColor;
